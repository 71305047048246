import * as signalR from '@aspnet/signalr';

export type SignalREvent = {
    name: string;
    data: any[];
}

export class SignalRConnection {
    private signalRConnectionRetries: number = 0;
    private connection: signalR.HubConnection;
    private connectionUrl: string | null = null;

    private userStopped: boolean = false;

    constructor(){
        this.connectionUrl = (process.env.REACT_APP_API_URL as string) + "/api";
 
        this.connection = new signalR.HubConnectionBuilder().withUrl(this.connectionUrl).build();
 
        this.connection.onclose(() => {
            if(this.userStopped === false){
                this.start();
            }
        });

        this.start();
    }

    public start = () => {
        try {
            if(this.connection.state === signalR.HubConnectionState.Disconnected){
                this.userStopped = false;
                this.connection.start();
            }
        } 
        catch (err: any) {
            if(this.signalRConnectionRetries >= 5){
                console.error("SignalR connection to " + this.connectionUrl + " failed to connect after 5 retries, canceling.");
                return;
            }
            this.signalRConnectionRetries++;
            setTimeout(() => this.start(), 5000);
        }
    }

    public subscribe = (eventName: string, callback: (event: SignalREvent) => void) => {
        setTimeout(() => {
            this.connection.off(eventName);
            this.connection.on(eventName, function () {
                const args = Array.from(arguments);
                for(var i = 0; i < args.length; i++){
                    if(typeof args[i] === "string"){
                        let temp = {};
                        try{
                            temp = JSON.parse(args[i]);
                        }
                        catch(err: any){
                            temp = {};
                        }

                        args[i] = temp;
                    }
                }
                callback({ name: eventName, data: args });
            });
        });
    };

    public unsubscribe = (eventName: string) => {
        setTimeout(() => {
            this.connection.off(eventName);
        });
    };

    public stop = () => {
        setTimeout(async () => {
            try{
                if(this.connection.state === signalR.HubConnectionState.Connected){
                    this.userStopped = true;
                    await this.connection.stop();
                }
            }
            catch(err: any) {
                console.error(err);
            } 
        });
    };
}