import React, { useState } from 'react';
import styles from './VisitorPanel.module.css';
import { Visitor, updateVisitorBadgeNumber } from '../../api/visitors';
import { SummaryElement } from '../SummaryElement/SummaryElement';
import { prettyLocalTime } from '../../utils/date-time.utils';

type Props = {
    visitor: Visitor,
    badgeNumberReadOnly: boolean,
    onClose: (visitor: Visitor) => void,
    onDelete?: (visitor: Visitor) => void,
    secureId?: boolean
};

export const VisitorPanel: React.FC<Props> = ({ visitor, badgeNumberReadOnly,  onClose, onDelete, secureId }) => {

    const [badgeNumber, setBadgeNumber] = useState<string>(visitor?.BadgeNumber);
    const [badgeNumberSaving, setBadgeNumberSaving] = useState<boolean>(false);

    const saveBadgeNumber = async () => {
        setBadgeNumberSaving(true);

        try {
            await updateVisitorBadgeNumber(visitor?.Id, badgeNumber);
            visitor.BadgeNumber = badgeNumber;
            setBadgeNumberSaving(false);
            onClose(visitor);
        }
        catch(err: any) {
            setBadgeNumberSaving(false);
            console.error(err);
        }
    };

    return visitor ? (
        <div className={styles.visitorPanelOverlay} onClick={_ => onClose(visitor)}>
                <div className={`${styles.visitorPanel} ${visitor?.VisitorPhotoUrl ? styles.visitorPanelWithPhoto : styles.visitorPanelNoPhoto}`} onClick={ev => {ev.preventDefault(); ev.stopPropagation()}}>
                    <div className="flex-row margin-bottom-15">
                        <h4 className="margin-top-0 margin-bottom-0 text-underlined text-24">Visitor Details</h4>
                        <div className="flex-fill"></div>
                        <button className="btn text-14 flex-align-self-start" onClick={_ => onClose(visitor)}>&#10006;</button>
                    </div>
                    <div className="flex-row">
                        <div className="flex-row flex-fill">
                            <div className={`${visitor?.VisitorPhotoUrl ? "flex-fill-60 margin-right-15" : "flex-fill"} ${styles.visitorPanelDetailsWrapper}`}>
                                <div className={`${styles.visitorPanelDetails} margin-bottom-30`}>
                                    <SummaryElement label="Arrived:" value={visitor?.ArrivalDateTime ? prettyLocalTime(visitor.ArrivalDateTime) : ""} />
                                    {visitor?.DepartureDateTime && <SummaryElement label="Departed:" value={prettyLocalTime(visitor.DepartureDateTime)} />}
                                    {visitor?.HostNotified && <SummaryElement label="Host Notified:" value={prettyLocalTime(visitor.HostNotified)} />}
                                </div>
                                <div className={`${styles.visitorPanelDetails} margin-bottom-30`}>
                                    {visitor?.Visiting && <SummaryElement label="Person Visiting:" value={visitor?.Visiting} />}
                                    {visitor?.VisitingCompany && <SummaryElement label="Company:" value={visitor?.VisitingCompany} />}
                                </div>
                                <div className={`${styles.visitorPanelDetails} margin-bottom-30`}>
                                    <SummaryElement label="Visitor Name:" value={visitor?.VisitorName || ""} />
                                    {visitor.VisitorCompany && <SummaryElement label="Visitor Company:" value={visitor?.VisitorCompany} />}
                                    {visitor.VisitorPhone && <SummaryElement label="Visitor Phone:" value={visitor?.VisitorPhone} />}
                                    {visitor.VisitorEmail && <SummaryElement label="Visitor Email:" value={visitor?.VisitorEmail} />}
                                    {visitor.VehicleRegistration && <SummaryElement label="Registration:" value={visitor?.VehicleRegistration} />}
                                    {(!badgeNumberReadOnly && visitor.BadgeNumberShown) && <div className="flex-row">
                                        <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                                            <div className="label">Badge Number</div>
                                            <input type="text" value={badgeNumber} onChange={ev => setBadgeNumber(ev.target.value)}></input>
                                        </div>
                                        <button className={`btn flex-align-self-end${badgeNumberSaving ? " loading" : ""}`} onClick={saveBadgeNumber}>SAVE</button>
                                    </div>}
                                    {(badgeNumberReadOnly && visitor?.BadgeNumber) && <SummaryElement label="Badge Number:" value={visitor?.BadgeNumber} />}

                                    {visitor?.Postcode && <SummaryElement label="Postcode:" value={visitor?.Postcode} />}
                                    {visitor?.MilesTravelled && <SummaryElement label="Miles Travelled:" value={visitor?.MilesTravelled} />}
                                    {visitor?.ModeOfTransport && <SummaryElement label="Mode of Transport:" value={visitor?.ModeOfTransport} />}
                                    {visitor?.DriverOfTheVehicle && <SummaryElement label="Driver of the Vehicle:" value={visitor?.DriverOfTheVehicle} />}
                                    {visitor?.FuelType && <SummaryElement label="Fuel Type:" value={visitor?.FuelType} />}
                                    {visitor?.SpecialAssistance && <SummaryElement label="Special Assistance:" value={visitor?.SpecialAssistance} />}    
                                </div>

                                {onDelete && (
                                    <div>
                                        <button className="btn red-button" onClick={_ => onDelete(visitor)}>DELETE</button>
                                    </div>
                                )}
                            </div>
                            {visitor?.VisitorPhotoUrl && (
                                <div className="flex-fill-40 flex-row">
                                    <div className="flex-fill">
                                        <div className={styles.visitorPanelPhotoWrapper}>
                                            <img className={styles.visitorPanelPhoto} alt="Visitor" src={visitor?.VisitorPhotoUrl}></img>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                   
                </div>
        </div>
    ) : null;
};