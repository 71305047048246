import { req, apiUrl } from "./helpers";
import { USER_TOKEN_NAME } from "./auth";

export type Location = {
    Id: number,
    GUID: string,
    Name: string,
    CommonLocationGUID: string;
    VisitingRequired: boolean,
    VisitingCompanyRequired: boolean,
    PhoneNumberRequired: boolean,
    EmailRequired: boolean,
    PhotoRequired: boolean,
    MilesTravelledRequired: boolean,
    ModeOfTransportRequired: boolean,
    DriverOfTheVehicleAndFuelTypeRequired: boolean,
    VisitorCompanyShown: boolean,
    VehicleRegistrationShown: boolean,
    BadgeNumberShown: boolean,
    PostcodeShown: boolean,
    SpecialAssistanceShown: boolean,
    Demo: boolean,
    SignInNotificationEmail: string,
    DataRetention: number,
    TimeZoneId: string,
    VisitorSignInInstructions: string,
    VisitorBadgeEnabled: boolean,
    SecureID: boolean,
    VisitorTypesEnabled: boolean,
    VisitorTypeName: string
    BadgeColor: string,
    RowColor: string,
    AutoConvertToVisitorType?: string,
    AutoConvertToVisitorTypeId?: number,
    AutoConvertToVisitorTypeExpiryDays?: number,
    AutoConvertToVisitorTypeMoveOnExpiry?: string,
    AutoConvertToVisitorTypeMoveOnExpiryId?: number,
    ParentLocationId?: number,
    AutoConvertEmails?: string,
    PreRegistration: boolean,
    EmailVerification: boolean
}

export type LocationCheck = {
    Id: number,
    LocationId: number,
    Label: string,
    Text: string,
    FormType: string,
    Enabled: boolean,
    EnforceTime: boolean,
    EnforceTimeMinutes: number,
    EnforceTimeSeconds: number
};

export type LocationCheckAddType = {
    Label: string,
    Text: string,
    Enabled: boolean,
    EnforceTime: boolean,
    EnforceTimeMinutes: number,
    EnforceTimeSeconds: number
};

export const getLocations = (): Promise<Location[]> => {
    return req("POST", apiUrl("/api/GetLocations"), {
        Email: "test@ontaap.com"
    },
    USER_TOKEN_NAME);
};

export const getLocation = (Id: number): Promise<Location> => {
    return req("POST", apiUrl("/api/GetLocation"), { Id }, USER_TOKEN_NAME);
};

export const getLocationChecks = (LocationId: number): Promise<LocationCheck[]> => {
    return req("POST", apiUrl("/api/GetLocationChecks"), { LocationId }, USER_TOKEN_NAME);
};

export const addLocation = (Name: string): Promise<any> => {
    return req("POST", apiUrl("/api/AddLocation"), { Name }, USER_TOKEN_NAME);
};

export const addLocationCheck = (LocationId: number, LocationCheck: LocationCheckAddType): Promise<any> => {
    return req("POST", apiUrl("/api/AddLocationCheck"), {...LocationCheck, LocationId }, USER_TOKEN_NAME);
};

export const saveLocation = (Location: Location, TypedLocation: Location): Promise<any> => {
    return req("POST", apiUrl("/api/SaveLocation"), {Location, TypedLocation}, USER_TOKEN_NAME);
};

export const saveLocationCheck = (locationCheck: LocationCheck): Promise<any> => {
    return req("POST", apiUrl("/api/SaveLocationCheck"), locationCheck, USER_TOKEN_NAME);
};

export const deleteLocationVisitors = (LocationId: number): Promise<any> => {
    return req("POST", apiUrl("/api/DeleteLocationVisitors"), { LocationId }, USER_TOKEN_NAME);
};

export const deleteLocation = (LocationId: number): Promise<any> => {
    return req("POST", apiUrl("/api/DeleteLocation"), { LocationId }, USER_TOKEN_NAME);
};

export const deleteLocationCheck = (LocationCheckId: number): Promise<any> => {
    return req("POST", apiUrl("/api/DeleteLocationCheck"), { LocationCheckId }, USER_TOKEN_NAME);
};

export type TimeZone = {
    Id: string,
    DisplayName: string
};

export const getTimeZoneInfo = (): Promise<TimeZone[]> => {
    return req("GET", apiUrl("/api/GetTimeZoneInfo"), null, USER_TOKEN_NAME);
};

export type GetTypedLocationOptionsResponse = {
    Id: number,
    VisitorTypeName: string,
    ParentLocationId?: number
}

export const getTypedLocationOptions = (LocationId: number): Promise<GetTypedLocationOptionsResponse[]> => {
    return req("POST", apiUrl("/api/GetTypedLocationOptions"), { LocationId }, USER_TOKEN_NAME);
}

export type AddTypedLocationResponse = {
    Id: string
};

export const addTypedLocation = (LocationId: number, VisitorTypeName: string): Promise<AddTypedLocationResponse> => {
    return req("POST", apiUrl("/api/AddTypedLocation"), { LocationId, VisitorTypeName }, USER_TOKEN_NAME);
}

export const deleteTypedLocation = (LocationId: number): Promise<Location[]> => {
    return req("POST", apiUrl("/api/DeleteTypedLocation"), { LocationId }, USER_TOKEN_NAME);
}

export const updateLocationAutoConvertEmails = (ParentLocationId: number, LocationId: number, Emails: string): Promise<Location[]> => {
    return req("POST", apiUrl("/api/UpdateLocationAutoConvertEmails"), { ParentLocationId, LocationId, Emails }, USER_TOKEN_NAME);
}

export type GetVisitorTypeEmailLocationsResponse = {
    Id: number,
    Name: string
}

export const getVisitorTypeEmailLocations = (ParentLocationId: number): Promise<GetVisitorTypeEmailLocationsResponse[]> => {
    return req("POST", apiUrl("/api/GetVisitorTypeEmailLocations"), { ParentLocationId }, USER_TOKEN_NAME);
}

export type GetAllVisitorTypeEmailsResponse = {
    VisitorTypeName: string,
    Emails: string
}

export const getVisitorTypeEmails = (ParentLocationId: number, LocationId: number | undefined, Search: string): Promise<GetAllVisitorTypeEmailsResponse[]> => {
    return req("POST", apiUrl("/api/GetVisitorTypeEmails"), { ParentLocationId, LocationId, Search }, USER_TOKEN_NAME);
}