import React, { createContext, useReducer, useContext, PropsWithChildren } from 'react';
import { SystemAdminAuthResult } from '../api/auth';

type SystemAdminState = {
    isLoggedIn: boolean,
    data: SystemAdminAuthResult
};

const initialState: SystemAdminState = {
    isLoggedIn: false,
    data: {
        DisplayName: "",
        ClientName: ""
    }
};

const SystemAdminStateContext = createContext<any>({});

const systemAdminStateReducer = (state: any, action: any): SystemAdminState => {
    return {...state, ...action};
};

export const SystemAdminStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(systemAdminStateReducer, initialState);

    return (
        <SystemAdminStateContext.Provider value={[state, dispatch]}>
            {children}
        </SystemAdminStateContext.Provider>
    );
};

export const useSystemAdminState = (): { systemAdminState: SystemAdminState, setSystemAdminState: (updates: Partial<SystemAdminState>) => void } => {
    const [systemAdminState, dispatch] = useContext(SystemAdminStateContext);

    const setSystemAdminState = (updates: Partial<SystemAdminState>) => {
        dispatch(updates);
    };

    return { systemAdminState, setSystemAdminState };
};