import React, { useState } from 'react';
import styles from '../../CssModules/Login.module.css';
import { login } from '../../api/auth';
import { useHistory } from 'react-router-dom';
import { useUserState } from '../../contexts/user.context';
import { Modal } from '../../components/Modal/Modal';
import { sendPasswordResetEmail } from '../../api/users';

export const Login: React.FC = () => {

    const { setUserState } = useUserState();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string>("");
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [resetEmailError, setResetEmailError] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);

    const history = useHistory();

    const onLogin = async () => {
        setLoading(true);
        setMessage("");

        try{
            const res = await login(email, password);

            setUserState({
                isLoggedIn: true,
                data: res
            });

            history.push("/");
        }
        catch(err: any) {
            setLoading(false);
            setMessage(err?.message || "An unexpected error occured");
        }
    };

    const onTryEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if(loading || ev.keyCode !== 13) // Enter
            return;
        
        onLogin();
    };

    const openResetPasswordModal = () => {
        setResetPasswordEmail(email);
        setResetPasswordModalOpen(true);
    }

    const onResetPassword = async () => {
        setSendingEmail(true);

        try{
            await sendPasswordResetEmail(resetPasswordEmail);
            setSendingEmail(false);
            setEmailSent(true);
        }
        catch(err: any) {
            setSendingEmail(false);
            setResetEmailError(err.message);
        }
    };

    return (
        <>
            <div className="container-cover">            
                <div className={styles.formContainer}>

                    <div className="form-control">
                        <input type="email" name="email" placeholder="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                    </div>

                    <div className="form-control">
                        <input type="password" name="password" placeholder="Password" value={password} onChange={ev => setPassword(ev.target.value)} onKeyUp={onTryEnter} />
                    </div>

                    <div className="flex-row flex-justify-space-between">
                        <button className={`btn${loading ? " loading" : ""}`} onClick={onLogin}>LOGIN</button>
                        <button className="btn" onClick={openResetPasswordModal} tabIndex={-1}>RESET PASSWORD</button>
                    </div>

                    {message && (
                        <div className={`text-center ${styles.message}`}>{message}</div>
                    )}
                </div>
            </div>

            {resetPasswordModalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Reset Password</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setResetPasswordModalOpen(false)}>&#10006;</button>
                    </div>

                    {!emailSent && (
                        <>
                            <div className="form-control">
                                <div className="label">Email</div>
                                <input type="email" name="email" placeholder="Email" value={resetPasswordEmail} onChange={ev => setResetPasswordEmail(ev.target.value)} />
                            </div>
                            
                            <button className={`btn margin-top-15${sendingEmail ? " loading" : ""}`} onClick={onResetPassword}>RESET PASSWORD</button>
                            
                            {resetEmailError && (
                                <div className={`text-center ${styles.message}`}>{resetEmailError}</div>
                            )}
                        </>
                    )}

                    {emailSent && (
                        <div className={`text-center ${styles.okMessage}`}>An email has been sent with a link to reset your password</div>
                    )}
                </Modal>
            )}
        </>
    );
};

/* 
<div className="container-fullwidth">
    <div className="text-center">
        <img width="493" height="197" src="assets/TAAPHere.png" alt="TAAP Here" title="TAAP Here" />
    </div>
    
    <div className={styles.formContainer}>

        <div className="form-control">
            <div className="label">Email</div>
            <input type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
        </div>

        <div className="form-control">
            <div className="label">Password</div>
            <input type="password" name="password" value={password} onChange={ev => setPassword(ev.target.value)} onKeyUp={onTryEnter} />
        </div>

        <div className="text-center">
            <button className={`btn${loading ? " loading" : ""}`} onClick={onLogin}>LOGIN</button>

            {message && <div className={`${styles.message}`}>{message}</div>}
        </div>
    </div>
</div>
*/