import React, { PropsWithChildren } from 'react';
import styles from './Modal.module.css';

type Props = {
    maxWidth?: string,
};

export const Modal: React.FC<PropsWithChildren<Props>> = ({ children, maxWidth }) => {
    
    return (
        <div className={styles.overlay}>
            <div className={styles.modal} style={{ maxWidth: maxWidth || undefined }}>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};