import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useUserState } from '../../contexts/user.context';
import { authCheck } from '../../api/auth';
import { Loading } from '../Loading/Loading';
import { useHistory } from 'react-router-dom';

export const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
    const { userState, setUserState } = useUserState();
    const [loading, setLoading] = useState<boolean>(true);

    const history = useHistory();

    useEffect(() => {
        if(!userState.isLoggedIn){
            (async () => {
                try {
                    const res = await authCheck();
                    setUserState({
                        isLoggedIn: true,
                        data: res
                    });
                    setLoading(false);
                }
                catch (err: any){
                    history.push("/login");
                }
            })();
        }
    }, [userState.isLoggedIn, setUserState, history]);

    if(userState.isLoggedIn)
        return <>{children}</>;

    if(loading) {
        return (
            <div className="container-fullwidth">
                <Loading />
            </div>
        );
    }

    // Unauthorized
    return (
        <div className="container-fullwidth">
            <h1>You are unauthorized to access this resource.</h1>
        </div>
    );
};