import React, { useEffect, useState } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { getLocations, Location } from '../../api/locations';
import { Visitor, getPagedVisitors, deleteVisitor, exportVisitors, PagedVisitor } from '../../api/visitors';
import { getUTCDateString, prettyLocalDateTime, prettyLocalTime } from '../../utils/date-time.utils';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useMessageBoxState } from '../../contexts/message-box.context';
import { VisitorPanel } from '../../components/VisitorPanel/VisitorPanel';
import { boolToSymbol } from '../../utils/symbol.utils';
import { getPagedPreRegistrations, hasPreRegistrationLocations, PreRegistration } from '../../api/pre-registrations';
import { Tabs } from '../../components/Tabs/Tabs';

export const AdminVisitorSearch: React.FC = () => {

    const [locations, setLocations] = useState<Location[]>([]);
    const [location, setLocation] = useState<Location>();
    const [visitors, setVisitors] = useState<PagedVisitor[]>([]);
    const [photoOpen, setPhotoOpen] = useState<boolean>(false);
    const [photoViewerSrc, setPhotoViewerSrc] = useState<string>("");

    const [searching, setSearching] = useState<boolean>(false);
    const [nexting, setNexting] = useState<boolean>(false);
    const [preving, setPreving] = useState<boolean>(false);
    const [exporting, setExporting] = useState<boolean>(false);

    const [visitorName, setVisitorName] = useState<string>("");
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date());

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);

    const { setMessageBoxState } = useMessageBoxState();

    const [visitorOpen, setVisitorOpen] = useState<boolean>();
    const [expandedVisitor, setExpandedVisitor] = useState<Visitor>();

    const [hasPreRegistrations, setHasPreRegistrations] = useState<boolean>();
    const [preRegistrations, setPreRegistrations] = useState<PreRegistration[]>([]);

    const [PRLocation, setPRLocation] = useState<Location>();
    const [PRVisitorName, setPRVisitorName] = useState<string>("");
    const [PRDateFrom, setPRDateFrom] = useState<Date>(new Date());
    const [PRDateTo, setPRDateTo] = useState<Date>(new Date());
    const [PRPageNumber, setPRPageNumber] = useState<number>(1);
    const [PRPageCount, setPRPageCount] = useState<number>(0);

    useEffect(() => {
        (async () => {
            try {
                const res = await getLocations();
                setLocations(res);

                const hasPRRes = await hasPreRegistrationLocations();
                setHasPreRegistrations(hasPRRes.HasPreRegistrations);
            }
            catch(err: any) {}
        })();
    }, []);

    const onExpandPhoto = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, src: string) => {
        ev.preventDefault();
        ev.stopPropagation();

        setPhotoViewerSrc(src);
        setPhotoOpen(true);
    };

    const onClosePhoto = () => {
        setPhotoViewerSrc("");
        setPhotoOpen(false);
    };

    const selectOptions = locations?.map(l => <option key={l.Id.toString()} label={l.Name} value={l.Id.toString()}>{l.Name}</option>);

    const onSelectLocation = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const locId = Number(ev.target.value);
        const loc = locations?.find(l => l.Id === locId);
        
        if(loc) {
            setLocation(loc);
            return;
        }

        setLocation(undefined);
    };

    const onPRSelectLocation = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const locId = Number(ev.target.value);
        const loc = locations?.find(l => l.Id === locId);
        
        if(loc) {
            setPRLocation(loc);
            return;
        }

        setPRLocation(undefined);
    };

    const onDeleteVistor = async (visitor: Visitor) => {
        setMessageBoxState({
            title: "Delete Visitor",
            message: "Are you sure you want to delete this visitor?",
            open: true,
            okayButtonText: "Yes, Delete",
            cancelButtonText: "No, Cancel",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;
                
                try {
                    setExpandedVisitor(undefined);
                    await deleteVisitor(visitor.Id);
                    await search(pageNumber);
                }
                catch(err: any){
                    console.error(err);
                }
            }
        });
    };

    const renderedVisitors = visitors.map(v => (
        <tr key={v.Id.toString()} onClick={_ => {setVisitorOpen(true); setExpandedVisitor(v);}} style={{ backgroundColor: v.RowColor }}>
            <td>{v.VisitorTypeName}</td>
            <td>{prettyLocalDateTime(v.ArrivalDateTime)}</td>
            <td>
                {v.DepartureDateTime && prettyLocalDateTime(v.DepartureDateTime)}  
            </td>
            <td>{v.HoursOnSite}</td>
            <td>
                {v.HostNotified && prettyLocalTime(v.HostNotified)}
            </td>
            <td>{v.Visiting}</td>
            <td>{v.VisitingCompany}</td>
            <td>{v.VisitorName}</td>
            <td>{v.VisitorCompany}</td>
            <td>{boolToSymbol(!!v.SpecialAssistance)}</td>
            <td>
                <div className="visitor-photo-wrapper">
                    <span className="visitor-photo-spacer"></span>
                    {v.VisitorPhotoUrl && <img src={v.VisitorPhotoUrl} alt="Visitor" className="visitor-photo" onClick={ev => onExpandPhoto(ev, (ev.target as any).src)} />}
                </div>
            </td>
        </tr>
    ));

    const renderedPreRegistrations = preRegistrations.map(pr => (
        <tr key={pr.Id.toString()} style={{ backgroundColor: pr.RowColor }}>
            <td>{pr.VisitorTypeName}</td>
            <td>{prettyLocalDateTime(pr.DateCreated)}</td>
            <td>{pr.Visiting}</td>
            <td>{pr.VisitingCompany}</td>
            <td>{pr.VisitorName}</td>
            <td>{pr.VisitorCompany}</td>
            <td>{boolToSymbol(!!pr.SpecialAssistance)}</td>
            <td>
                <div className="visitor-photo-wrapper">
                    <span className="visitor-photo-spacer"></span>
                    {pr.VisitorPhotoUrl && <img src={pr.VisitorPhotoUrl} alt="Visitor" className="visitor-photo" onClick={ev => onExpandPhoto(ev, (ev.target as any).src)} />}
                </div>
            </td>
        </tr>
    ));

    const search = async (pageNum: number) => {
        setPageNumber(pageNum);

        try{
            const response = await getPagedVisitors({
                LocationId: location?.Id,
                DateFrom: getUTCDateString(dateFrom),
                DateTo: getUTCDateString(dateTo),
                VisitorName: visitorName,
                PageSize: 20,
                PageNumber: pageNum
            });

            setPageCount(response.Info.PageCount);
            setVisitors(response.Result);
        }
        catch(err: any) {
            console.error(err);
        }
    };

    const prSearch = async (pageNum: number) => {
        setPRPageNumber(pageNum);

        try{
            const response = await getPagedPreRegistrations({
                LocationId: PRLocation?.Id,
                DateFrom: getUTCDateString(PRDateFrom),
                DateTo: getUTCDateString(PRDateTo),
                VisitorName: PRVisitorName,
                PageSize: 20,
                PageNumber: pageNum
            });

            setPRPageCount(response.Info.PageCount);
            setPreRegistrations(response.Result);
        }
        catch(err: any) {
            console.error(err);
        }
    };

    const onSearch = async () => {
        setSearching(true);
        await search(1);
        setSearching(false);
    }

    const onPRSearch = async () => {
        setSearching(true);
        await prSearch(1);
        setSearching(false);
    };

    const onPrev = async () => {
        setPreving(true);
        const page = pageNumber - 1;
        await search(page);
        setPreving(false);
    };

    const onPRPrev = async () => {
        setPreving(true);
        const page = PRPageNumber - 1;
        await prSearch(page);
        setPreving(false);
    };

    const onNext = async () => {
        setNexting(true);
        const page = pageNumber + 1;
        await search(page);
        setNexting(false);
    };

    const onPRNext = async () => {
        setNexting(true);
        const page = PRPageNumber + 1;
        await prSearch(page);
        setNexting(false);
    };

    const onExport = async () => {
        setExporting(true);
        try{
            const response = await exportVisitors({
                LocationId: location?.Id,
                DateFrom: getUTCDateString(dateFrom),
                DateTo: getUTCDateString(dateTo),
                VisitorName: visitorName
            });

            if(response) {
                saveData(response, "VisitorDataExport.xlsx");
            }

            setExporting(false);
        }
        catch(err: any) {
            setExporting(false);
            console.error(err);
        }
    };

    const saveData = (blob: Blob, fileName: string) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(blob, fileName);
            return;
        }
        
        const a = document.createElement("a");
        a.style.display = "none";
        const objectUrl = window.URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(objectUrl);
    };

    const visitorsTab = (
        <>
            <div className="flex-row margin-top-15">
                <div className="flex-large-title">Visitor Search</div>
                <div className="flex-fill"></div>
            </div>
            <div className="flex-row">
                <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                    <div className="label">Location</div>
                    <select value={location?.Id || 0} onChange={onSelectLocation}>
                        <option key="0" label="All" value="0">All</option>
                        {selectOptions}
                    </select>
                </div>
                <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                    <div className="label">Visitor Name</div>
                    <input type="text" value={visitorName} onChange={ev => setVisitorName(ev.target.value)}></input>
                </div>
                <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                    <div className="label">Date From</div>
                    <DatePicker selected={dateFrom} onChange={d => setDateFrom(d as any)} dateFormat="dd/MM/yyyy" />
                </div>
                <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                    <div className="label">Date To</div>
                    <DatePicker selected={dateTo} onChange={d => setDateTo(d as any)} dateFormat="dd/MM/yyyy" />
                </div>
                <button className={`btn flex-align-self-end margin-right-15${searching ? " loading" : ""}`} onClick={onSearch}>SEARCH</button>
                <button className={`btn flex-align-self-end${exporting ? " loading" : ""} ${visitors && visitors.length > 0 ? "" : " btn-disabled"}`} onClick={onExport}>EXPORT</button>
                <div className="flex-fill"></div>
            </div>
            
            
            <table className="table table-striped table-font-large table-clickable">
                <thead>
                    <tr>
                    <th>Signed In As</th>
                    <th>Arrived</th>
                    <th>Departed</th>
                    <th>Hours On Site</th>
                    <th>Host Notified</th>
                    <th>Visiting</th>
                    <th>Company</th>
                    <th>Visitor Name</th>
                    <th>Visitor Company</th>
                    <th>Special Assistance</th>
                    <th>Photo</th>
                    </tr>
                </thead>
                <tbody>
                    {renderedVisitors}
                </tbody>
            </table>


            {pageCount > 0 && (
                <div className="flex-row flex-justify-center">
                    <button className={`btn flex-align-self-end margin-left-15 margin-right-15${preving ? " loading" : ""}`} style={{ visibility: pageNumber > 1 ? "visible": "hidden" }} onClick={onPrev}>PREV</button>
                    <div className="flex-title">Page {pageNumber} / {pageCount}</div>
                    <button className={`btn flex-align-self-end margin-left-15 margin-right-15${nexting ? " loading" : ""}`} style={{ visibility: pageCount > pageNumber ? "visible": "hidden" }} onClick={onNext}>NEXT</button>
                </div>
            )}

        </>
    );

    let preRegistrationTab = null;
    if(hasPreRegistrations) {
        preRegistrationTab = (
            <>
                <div className="flex-row margin-top-15">
                    <div className="flex-large-title">Pre-Registration Search</div>
                    <div className="flex-fill"></div>
                </div>
                <div className="flex-row">
                    <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                        <div className="label">Location</div>
                        <select value={PRLocation?.Id || 0} onChange={onPRSelectLocation}>
                            <option key="0" label="All" value="0">All</option>
                            {selectOptions}
                        </select>
                    </div>
                    <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                        <div className="label">Pre-Registration Name</div>
                        <input type="text" value={PRVisitorName} onChange={ev => setPRVisitorName(ev.target.value)}></input>
                    </div>
                    <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                        <div className="label">Date From</div>
                        <DatePicker selected={PRDateFrom} onChange={d => setPRDateFrom(d as any)} dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                        <div className="label">Date To</div>
                        <DatePicker selected={PRDateTo} onChange={d => setPRDateTo(d as any)} dateFormat="dd/MM/yyyy" />
                    </div>
                    <button className={`btn flex-align-self-end margin-right-15${searching ? " loading" : ""}`} onClick={onPRSearch}>SEARCH</button>
                    {/* <button className={`btn flex-align-self-end${exporting ? " loading" : ""} ${visitors && visitors.length > 0 ? "" : " btn-disabled"}`} onClick={onExport}>EXPORT</button> */}
                    <div className="flex-fill"></div>
                </div>
                
                
                <table className="table table-striped table-font-large">
                    <thead>
                        <tr>
                        <th>Pre-Registered As</th>
                        <th>Date / Time</th>
                        <th>Visiting</th>
                        <th>Visiting Company</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Special Assistance</th>
                        <th>Photo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderedPreRegistrations}
                    </tbody>
                </table>


                {PRPageCount > 0 && (
                    <div className="flex-row flex-justify-center">
                        <button className={`btn flex-align-self-end margin-left-15 margin-right-15${preving ? " loading" : ""}`} style={{ visibility: PRPageNumber > 1 ? "visible": "hidden" }} onClick={onPRPrev}>PREV</button>
                        <div className="flex-title">Page {PRPageNumber} / {PRPageCount}</div>
                        <button className={`btn flex-align-self-end margin-left-15 margin-right-15${nexting ? " loading" : ""}`} style={{ visibility: PRPageCount > PRPageNumber ? "visible": "hidden" }} onClick={onPRNext}>NEXT</button>
                    </div>
                )}

            </>
        );
    }

    return (
        <>
            <Menu isAdmin={true} />
            <div className="container-fullwidth">
                {hasPreRegistrations && (
                    <Tabs tabs={[
                        {
                            label: "Visitor Search",
                            component: visitorsTab
                        },
                        {
                            label: "Pre-Registration Search",
                            component: preRegistrationTab
                        }
                    ]} />
                )}
                {!hasPreRegistrations && visitorsTab}
            </div>

            {photoOpen && (
                <div className="photo-viewer-overlay" onClick={onClosePhoto}>
                    <div className="photo-viewer">
                        <span className="photo-viewer-spacer"></span>
                        <img className="photo-viewer-photo" alt="Visitor" src={photoViewerSrc}></img>
                    </div>
                </div>
            )}

            {visitorOpen && expandedVisitor && (
                <VisitorPanel 
                    visitor={expandedVisitor} 
                    badgeNumberReadOnly={true}
                    onClose={() => {setVisitorOpen(false); setExpandedVisitor(undefined);}} 
                    onDelete={async v => {setVisitorOpen(false); await onDeleteVistor(v);}} 
                />
            )}
        </>
    );
};