import React, { PropsWithChildren, useEffect, useState } from 'react';
import { systemAdminAuthCheck } from '../../api/auth';
import { Loading } from '../Loading/Loading';
import { useHistory } from 'react-router-dom';
import { useSystemAdminState } from '../../contexts/system-admin.context';

export const SystemAdminAuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
    const { systemAdminState, setSystemAdminState } = useSystemAdminState();
    const [loading, setLoading] = useState<boolean>(true);

    const history = useHistory();

    useEffect(() => {
        if(!systemAdminState.isLoggedIn){
            (async () => {
                try {
                    const res = await systemAdminAuthCheck();
                    setSystemAdminState({
                        isLoggedIn: true,
                        data: res
                    });
                    setLoading(false);
                }
                catch (err: any){
                    history.push("/systemadmin/login");
                }
            })();
        }
    }, [systemAdminState.isLoggedIn, setSystemAdminState, history]);

    if(systemAdminState.isLoggedIn)
        return <>{children}</>;

    if(loading) {
        return (
            <div className="container-fullwidth">
                <Loading />
            </div>
        );
    }

    // Unauthorized
    return (
        <div className="container-fullwidth">
            <h1>You are unauthorized to access this resource.</h1>
        </div>
    );
};