import { req, apiUrl } from "./helpers";
import { USER_TOKEN_NAME } from "./auth";

export type User = {
    Id: number;
    DisplayName: string;
    Email: string;
    DefaultLocationId: number;
    DefaultLocationName: string;
    Role: string;
    Active: boolean;
}

export type NewUser = {
    DisplayName: string;
    Email: string;
    Password: string;
    DefaultLocationId: number;
    Role: string;
}

export const getUsers = (): Promise<User[]> => {
    return req("GET", apiUrl("/api/GetUsers"), null, USER_TOKEN_NAME);
};

export const getUser = (Id: number): Promise<User> => {
    return req("POST", apiUrl("/api/GetUser"), { Id }, USER_TOKEN_NAME);
};

export const saveUser = (user: User): Promise<any> => {
    return req("POST", apiUrl("/api/SaveUser"), user, USER_TOKEN_NAME);
};

export const addUser = (newUser: NewUser) => {
    return req("POST", apiUrl("/api/AddUser"), newUser, USER_TOKEN_NAME);
};

export const deleteUser = (UserId: number) => {
    return req("POST", apiUrl("/api/DeleteUser"), { UserId }, USER_TOKEN_NAME);
};

export const changePassword = (UserId: number, Password: string) => {
    return req("POST", apiUrl("/api/ChangeUserPassword"), { UserId, Password }, USER_TOKEN_NAME);
};

export const sendPasswordResetEmail = (Email: string) => {
    return req("POST", apiUrl("/api/SendPasswordResetEmail"), { Email }, USER_TOKEN_NAME);
};

export const resetPassword = (NewPassword: string, NewPasswordConfirmation: string, ResetGUID: string) => {
    return req("POST", apiUrl("/api/ResetUserPassword"), { NewPassword, NewPasswordConfirmation, ResetGUID }, USER_TOKEN_NAME);
};

type SetUserAdminWelcomeResponse = {
    Token: string
};

export const setUserAdminWelcome = async (AdminWelcome: boolean) => {
    const res: SetUserAdminWelcomeResponse = await req("POST", apiUrl("/api/SetUserAdminWelcome"), { AdminWelcome }, USER_TOKEN_NAME);
    localStorage.setItem(USER_TOKEN_NAME, res.Token);
};
