import { req, apiUrl } from "./helpers";
import { USER_TOKEN_NAME } from "./auth";

export type PreRegistration = {
    Id: number;
    DateCreated: string,
    Visiting: string;
    VisitorName: string;
    VisitorPhone: string;
    VisitorEmail: string;
    VehicleRegistration: string;
    VisitorPhotoUrl: string;
    VisitorCompany?: string;
    VisitingCompany?: string;
    BadgeNumber: string;
    Postcode: string;
    MilesTravelled: string;
    ModeOfTransport: string;
    DriverOfTheVehicle: string;
    FuelType: string;

    SpecialAssistance: string;
    RowColor: string;
    VisitorTypeName: string;
    BadgeNumberShown: boolean;
};

export type GetPreRegistrationsRequest = {
    LocationId?: number,
    DateFrom?: string,
    DateTo?: string,
    VisitorName?: string,
    PageSize: number,
    PageNumber: number
};

export type PagedPreRegistrationsResultInfo = {
    Count: number,
    PageCount: number
};

export type PagedPreRegistrationResult = {
    Result: PreRegistration[],
    Info: PagedPreRegistrationsResultInfo
};


export const getPagedPreRegistrations = (pagedVisitorsRequest: GetPreRegistrationsRequest): Promise<PagedPreRegistrationResult> => {
    return req("POST", apiUrl("/api/GetPagedPreRegistrations"), pagedVisitorsRequest, USER_TOKEN_NAME);
};

export type HasPreRegistrationsResult = {
    HasPreRegistrations: boolean
};

export const hasPreRegistrationLocations = (): Promise<HasPreRegistrationsResult> => {
    return req("GET", apiUrl("/api/HasPreRegistrations"), null, USER_TOKEN_NAME);
}