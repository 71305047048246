import React, { useEffect, useState } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Location, getLocation, getTypedLocationOptions, deleteTypedLocation, saveLocation, GetTypedLocationOptionsResponse, updateLocationAutoConvertEmails } from '../../api/locations';
import { getVisitorTypeMappings, VisitorTypeMapping, addVisitorTypeMapping, removeVisitorTypeMappings, moveVisitorTypes } from '../../api/mapping';
import { getVisitorsWithMappings, VisitorWithMapping } from '../../api/visitors';
import { prettyLocalDateTime, getUTCDateString, prettyLocalDate } from '../../utils/date-time.utils';
import { Loading } from '../../components/Loading/Loading';
import { Modal } from '../../components/Modal/Modal';
import { CheckBox } from '../../components/CheckBox/CheckBox';
import DatePicker from 'react-datepicker';
import { useMessageBoxState } from '../../contexts/message-box.context';
import { Tabs } from '../../components/Tabs/Tabs';
import saveMessageStyles from '../../CssModules/SaveMessage.module.css';
import { ClientRegisteredUserWithDeviceId, getClientRegisteredUsersWithDeviceIds } from '../../api/client-registered-users';
import { getQRCodePNG } from '../../utils/print.utils';
import { downloadBase64String } from '../../utils/download.utils';

// Type whatever you expect in 'this.props.match.params.*'
type PathParams = {
    locationId: string,
    subLocationId: string
}

// Your component own properties
type Props = RouteComponentProps<PathParams>;

type TickableVisitorTypeMappings = VisitorTypeMapping & { Ticked: boolean };

const colors = [
    { name: "White", color: "#ffffff" },
    { name: "Blue", color: "#67C8FF" },
    { name: "Brown", color: "#C3B8AA" },
    { name: "Cream", color: "#f6e3ce" },
    { name: "Green", color: "#b5ead7" },
    { name: "Orange", color: "#ffc87a" },
    { name: "Pink", color: "#ffd1dc" },
    { name: "Purple", color: "#c0aee0" },
    { name: "Red", color: "#ff9aa2" },
    { name: "Turquoise", color: "#cef6f5" },
    { name: "Yellow", color: "#fdfd96" }
];

export const AdminLocationManage: React.FC<Props> = ({ match: { params: { locationId, subLocationId } } }) => {

    const history = useHistory();
    const { setMessageBoxState } = useMessageBoxState();
    const [location, setLocation] = useState<Location>();
    const [typedLocation, setTypedLocation] = useState<Location>();
    const [typedLocationAutoConvertEmailsLength, setTypedLocationAutoConvertEmailsLength] = useState<number>(0);
    const [mappings, setMappings] = useState<TickableVisitorTypeMappings[]>();
    const [filteredMappings, setFilteredMappings] = useState<TickableVisitorTypeMappings[]>();
    const [visitors, setVisitors] = useState<VisitorWithMapping[]>();
    const [typedLocationOptions, setTypedLocationOptions] = useState<GetTypedLocationOptionsResponse[]>([]);
    const [locationIdToMoveTo, setLocationIdToMoveTo] = useState<number>();
    const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false);
    const [allTicked, setAllTicked] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [searchingVisitors, setSearchingVisitors] = useState<boolean>(false);
    const [searchInVisitorType, setSearchInVisitorType] = useState<string>("");
    const [newVisitorTypeName, setNewVisitorTypeName] = useState<string>("");
    const [uploadingCSV, setUploadingCSV] = useState<boolean>(false);

    const [CRUs, setCRUs] = useState<ClientRegisteredUserWithDeviceId[]>([]);
    const [CRUSearch, setCRUSearch] = useState<string>("");
    const [searchingCRUs, setSearchingCRUs] = useState<boolean>(false);
    const [addCRUModalOpen, setAddCRUModalOpen] = useState<boolean>(false);
    const [selectedCRUToAdd, setSelectedCRUToAdd] = useState<ClientRegisteredUserWithDeviceId>();

    const [expiryDate, setExpiryDate] = useState<Date>((() => {
        const d = new Date();
        d.setDate(d.getDate() + 7);
        return d;
    })());

    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [selectedVisitorToAdd, setSelectedVisitorToAdd] = useState<VisitorWithMapping>();

    const [saving, setSaving] = useState<boolean>(false);
    const [saveMessage, setSaveMessage] = useState<{ message: string, success: boolean }>({ message: "", success: true });

    // Prereg popup
    const [dlqrModalOpen, setDlqrAddModalOpen] = useState<boolean>(false);
    const [preRegQRImageString, setPreRegQRImageString] = useState<string>("");
    const [preRegQRUrl, setPreRegQRUrl] = useState<string>("");

    useEffect(() => {
        (async () => {
            const loc = await getLocation(Number(locationId));
            setLocation(loc);

            const tloc = await getLocation(Number(subLocationId));
            setTypedLocation(tloc);
            setNewVisitorTypeName(tloc.VisitorTypeName);
            let split = [];
            if(tloc.AutoConvertEmails)
                split = tloc.AutoConvertEmails.split(",");
            setTypedLocationAutoConvertEmailsLength(split.length);
    
            const maps = await getVisitorTypeMappings(Number(subLocationId));
            setMappings(maps as TickableVisitorTypeMappings[]);
            setFilteredMappings(maps as TickableVisitorTypeMappings[]);

            const res = await getVisitorsWithMappings(Number(locationId), "");
            setVisitors(res);

            const CRUres = await getClientRegisteredUsersWithDeviceIds({ Search: null });
            setCRUs(CRUres);

            let typedLocationOptionsRes = await getTypedLocationOptions(Number(locationId));
            setTypedLocationOptions(typedLocationOptionsRes);
        })();
        
        
    }, [locationId, subLocationId]);

    if(!location || !typedLocation || !visitors || !mappings || !filteredMappings)
        return <Loading />

    const onSave = async () => {
        setSaveMessage({ message: "", success: true });
        if(!location || !typedLocation)
            return;

        setSaving(true);

        if(!typedLocation.AutoConvertToVisitorTypeExpiryDays || 
            isNaN(typedLocation.AutoConvertToVisitorTypeExpiryDays) || 
            typedLocation.AutoConvertToVisitorTypeExpiryDays <= 0){
            typedLocation.AutoConvertToVisitorTypeExpiryDays = undefined;
        }
        else {
            typedLocation.AutoConvertToVisitorTypeExpiryDays = Math.floor(typedLocation.AutoConvertToVisitorTypeExpiryDays);
        }

        if(typedLocation.AutoConvertToVisitorTypeMoveOnExpiryId === 0)
            typedLocation.AutoConvertToVisitorTypeMoveOnExpiryId = undefined;

        if(typedLocation.AutoConvertToVisitorTypeId === 0)
            typedLocation.AutoConvertToVisitorTypeId = undefined;

        try{
            const newTypedLocation = {...typedLocation, VisitorTypeName: newVisitorTypeName};
            await saveLocation(location, newTypedLocation);
            setTypedLocation(newTypedLocation);
            const maps = await getVisitorTypeMappings(Number(subLocationId));
            setMappings(maps as TickableVisitorTypeMappings[]);
            const lcs = searchInVisitorType.toLowerCase();
            setFilteredMappings(maps.filter(v => v.DisplayName.toLowerCase().includes(lcs)) as TickableVisitorTypeMappings[]);
            setAllTicked(false);
            setSaveMessage({ message: "Saved!", success: true });
        }
        catch (err: any) {
            setSaveMessage({ message: err.message, success: false });
            console.error(err);
        }

        setSaving(false);
    };


    const onSearch = async () => {
        setSearchingVisitors(true);
        const res = await getVisitorsWithMappings(Number(locationId), search);
        setVisitors(res);
        setSearchingVisitors(false);
    };

    const onCRUSearch = async () => {
        setSearchingCRUs(true);
        const CRUres = await getClientRegisteredUsersWithDeviceIds({ Search: CRUSearch });
        setCRUs(CRUres);
        setSearchingCRUs(false);
    };
    
    const onSearchInVisitorType = (newValue: string) => {
        setSearchInVisitorType(newValue);
        setAllTicked(false); 
        setMappings(mappings.map(m => ({...m,Ticked: false})));
        const lcs = newValue.toLowerCase();
        setFilteredMappings(mappings.filter(v => v.DisplayName.toLowerCase().includes(lcs)));
    }

    const onAddModalOpen = (v: VisitorWithMapping) => {
        setSelectedVisitorToAdd(v);
        setExpiryDate((() => {
            const d = new Date();
            d.setDate(d.getDate() + (typedLocation?.AutoConvertToVisitorTypeExpiryDays || 7));
            return d;
        })());

        setAddModalOpen(true);
    };

    const onCRUAddModalOpen = (v: ClientRegisteredUserWithDeviceId) => {
        setSelectedCRUToAdd(v);
        setExpiryDate((() => {
            const d = new Date();
            d.setDate(d.getDate() + (typedLocation?.AutoConvertToVisitorTypeExpiryDays || 7));
            return d;
        })());

        setAddCRUModalOpen(true);
    };

    const onAddVisitorTypeMapping = async () => {
        setAddModalOpen(false);

        if(!selectedVisitorToAdd)
            return;

        try{
            await addVisitorTypeMapping(
                selectedVisitorToAdd.DeviceGUID, 
                Number(subLocationId), 
                selectedVisitorToAdd.VisitorName, 
                (typedLocation.AutoConvertToVisitorTypeExpiryDays && !typedLocation.AutoConvertToVisitorTypeId && expiryDate ? getUTCDateString(expiryDate) : undefined));

            const maps = await getVisitorTypeMappings(Number(subLocationId));
            setMappings(maps as TickableVisitorTypeMappings[]);
            const lcs = searchInVisitorType.toLowerCase();
            setFilteredMappings(maps.filter(v => v.DisplayName.toLowerCase().includes(lcs)) as TickableVisitorTypeMappings[]);
        }
        catch(err: any) {
            setMessageBoxState({
                open: true,
                title: "Error",
                message: err.message,
                options: {
                    hasCancel: false,
                    redOkayButton: false
                }
            });
        }
    };

    const onAddCRUTypeMapping = async () => {
        setAddCRUModalOpen(false);

        if(!selectedCRUToAdd || !selectedCRUToAdd.DeviceId)
            return;

        try{
            await addVisitorTypeMapping(
                selectedCRUToAdd.DeviceId, 
                Number(subLocationId), 
                selectedCRUToAdd.Name, 
                (typedLocation.AutoConvertToVisitorTypeExpiryDays && !typedLocation.AutoConvertToVisitorTypeId && expiryDate ? getUTCDateString(expiryDate) : undefined));

            const maps = await getVisitorTypeMappings(Number(subLocationId));
            setMappings(maps as TickableVisitorTypeMappings[]);
            const lcs = searchInVisitorType.toLowerCase();
            setFilteredMappings(maps.filter(v => v.DisplayName.toLowerCase().includes(lcs)) as TickableVisitorTypeMappings[]);
        }
        catch(err: any) {
            setMessageBoxState({
                open: true,
                title: "Error",
                message: err.message,
                options: {
                    hasCancel: false,
                    redOkayButton: false
                }
            });
        }
    };

    const onRemoveFromVisitorType = async () => {
        setMessageBoxState({
            open: true,
            title: `Remove From ${typedLocation.VisitorTypeName}`,
            message: `Are you sure you want to remove the selected visitors from this visitor type? THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, REMOVE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                const ids = filteredMappings.filter(m => m.Ticked === true).map(m => m.Id);
                await removeVisitorTypeMappings(ids);
                const maps = await getVisitorTypeMappings(Number(subLocationId));
                setMappings(maps as TickableVisitorTypeMappings[]);
                const lcs = searchInVisitorType.toLowerCase();
                setFilteredMappings(maps.filter(v => v.DisplayName.toLowerCase().includes(lcs)) as TickableVisitorTypeMappings[]);
                setAllTicked(false);
            }
        });
        
    };

    const onMoveVisitorTypes = async () => {
        setMoveModalOpen(false);

        if(!locationIdToMoveTo)
            return;

        const ids = filteredMappings.filter(m => m.Ticked === true).map(m => m.Id);
        await moveVisitorTypes(ids, locationIdToMoveTo);
        const maps = await getVisitorTypeMappings(Number(subLocationId));
        setMappings(maps as TickableVisitorTypeMappings[]);
        const lcs = searchInVisitorType.toLowerCase();
        setFilteredMappings(maps.filter(v => v.DisplayName.toLowerCase().includes(lcs)) as TickableVisitorTypeMappings[]);
        setAllTicked(false);
    };

    const renderedVisitors = visitors.map(v => (
        <tr key={v.Id.toString()} style={{ backgroundColor: v.RowColor }}>
            <td>{v.VisitorName}</td>
            <td>{prettyLocalDateTime(v.ArrivalDateTime)}</td>
            <td>{v.VisitorTypeName}</td>
            <td>
                <button className="btn margin-left-15 margin-right-15" onClick={() => onAddModalOpen(v)}>ADD</button>
            </td>
        </tr>
    ));

    const renderedCRUs = CRUs.map((v, i) => (
        <tr key={i.toString()}>
            <td>{v.Name}</td>
            <td>{v.Email}</td>
            <td>{v.Category}</td>
            <td>
                <button className="btn margin-left-15 margin-right-15" onClick={() => onCRUAddModalOpen(v)}>ADD</button>
            </td>
        </tr>
    ));

    const renderedVisitorTypes = filteredMappings.map((m, i) => (
        <tr key={m.Id.toString()} style={{width: "100px"}}>
            <td><CheckBox label="" checked={m.Ticked} onChecked={c => {filteredMappings[i].Ticked = c; setFilteredMappings([...filteredMappings]);}} /></td>
            <td>{m.DisplayName}</td>
            <td>{prettyLocalDate(m.ExpiryDate)}</td>
            <td>{m.MoveOnExpiry ? m.MoveOnExpiry : (m.ExpiryDate ? location.VisitorTypeName : "")}</td>
        </tr>
    ));

    const onDeleteTypedLocation = () => {
        setMessageBoxState({
            open: true,
            title: "Delete Visitor Type",
            message: `Are you sure you want to delete '${typedLocation?.VisitorTypeName}'? THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, DELETE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                try{
                    await deleteTypedLocation(typedLocation?.Id || 0);
                    const typedLocs = await getTypedLocationOptions(Number(locationId));
                    setTypedLocationOptions(typedLocs);
                    history.goBack();
                }
                catch(err: any){
                    setMessageBoxState({
                        open: true,
                        title: "Error",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                }
            }
        });
    };

    const onEmailCSVSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
        ev.persist();
        if(!ev.target.files || ev.target.files.length === 0){
            (ev as any).target.value = null;
            return;
        }

        const file = ev.target.files[0];

        if(!file.name.toLowerCase().endsWith(".csv")){
            (ev as any).target.value = null;
            return;
        }

        setMessageBoxState({
            open: true,
            title: "Upload Emails CSV",
            message: `Are you sure you want to upload this CSV file? the emails in this file will be merged with any that currently exist. THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, UPLOAD",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: false
            },
            callback: async (confirmed) => {
                if(!confirmed){
                    (ev as any).target.value = null;
                    return;
                }

                setUploadingCSV(true);

                // Extract emails
                let text = null;
                try{
                    text = await extractText(file);
                }
                catch(err: any){}

                if(!text) {
                    setUploadingCSV(false);
                    (ev as any).target.value = null;
                    return;
                }

                const emails = text.split("\n").map(s => s.split(",")[0].replace(/^[\s"]+/, "").replace(/[\s"]+$/, "").toLowerCase()).filter(s => !!s);

                // Merge with current and remove duplicates
                let current = ((typedLocation.AutoConvertEmails || null)?.split(",") || []);
                for(const email of emails){
                    if(current.indexOf(email) === -1) {
                        current.push(email);
                    }
                }

                // Order result
                current = current.sort()

                // Process request
                const csv = current.join(",");
                try{
                    await updateLocationAutoConvertEmails(location.Id, typedLocation.Id, csv);
                }
                catch(err: any) {
                    setUploadingCSV(false);
                    (ev as any).target.value = null;
                    setMessageBoxState({
                        open: true,
                        title: "Emails Upload Failed",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                    return;
                }
                setTypedLocation({...typedLocation, AutoConvertEmails: csv });
                setTypedLocationAutoConvertEmailsLength(current.length);

                setUploadingCSV(false);
                (ev as any).target.value = null;

                setMessageBoxState({
                    open: true,
                    title: "Emails Uploaded",
                    message: `The emails have been uploaded for this visitor type successfully.`,
                    options: {
                        hasCancel: false,
                        redOkayButton: false
                    }
                });
            }
        });
    };

    const onClearEmails = () => {
        setMessageBoxState({
            open: true,
            title: "Clear Emails",
            message: `Are you sure you want to clear ALL email addresses for this VisitorType? THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, CLEAR",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed){
                    return;
                }

                await updateLocationAutoConvertEmails(location.Id, typedLocation.Id, "");
                setTypedLocation({...typedLocation, AutoConvertEmails: "" });

                setMessageBoxState({
                    open: true,
                    title: "Emails Cleared",
                    message: `Emails under this visitor type have been cleared successfully.`,
                    options: {
                        hasCancel: false,
                        redOkayButton: false
                    }
                });
            }
        });
    };

    const extractText = (file: File): Promise<string> => {
        return new Promise<string>((res, rej) => {
            const reader = new FileReader();

            reader.onload = () => {
                const fileContents = reader.result as string;
                res(fileContents);
            };
    
            reader.readAsText(file);
        });
    };

    const onDownloadPreRegistrationQRCode = async () => {
        const qrString = `${window.location.origin}/meeting/?t=${encodeURIComponent("SignIn")}&l=${encodeURIComponent(location.GUID)}&prl=${encodeURIComponent(typedLocation?.GUID)}`;
        setPreRegQRUrl(qrString);
        setPreRegQRImageString(await getQRCodePNG(qrString));
        setDlqrAddModalOpen(true);
    };

    if(location && typedLocation && visitors && mappings && filteredMappings)
        return (
            <>
                <Menu isAdmin={true} />
                <div className="container-1200">
                    <button className="btn margin-bottom-15" onClick={_ => { history.goBack(); history.replace(`/admin/locations/${locationId}/?tl=${subLocationId}`); }}>BACK</button>
                    
                    <Tabs tabs={[
                        {
                            label: `Settings for ${typedLocation.VisitorTypeName}`,
                            component: (
                                <>
                                    <button className={`btn margin-top-15${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                                    <div className="bordered-container margin-top-15">
                                        <h1 className="no-margin-top">Settings</h1>

                                        {!typedLocation.ParentLocationId && <p className="no-margin-top" style={{ color: "red" }}>This Visitor Type is the default that all visitors will automatically sign in against for the first time.</p>}

                                        <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                            <div className="label">Visitor Type Name</div>
                                            <input value={newVisitorTypeName} onChange={ev => setNewVisitorTypeName(ev.target.value)}></input>
                                        </div>

                                        <div className="flex-row">
                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                <div className="label">Visitor Book Row Colour</div>
                                                <select value={typedLocation.RowColor} style={{ backgroundColor: typedLocation.RowColor }} onChange={ev => setTypedLocation({...typedLocation, RowColor: ev.target.value })} >
                                                    {colors.map((n, index) => (
                                                        <option key={index.toString()} style={{ backgroundColor: n.color }} label={n.name} value={n.color}>{n.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <p className="flex-fill flex-align-self-center margin-left-15">Select a colour to highlight which Visitor Type your visitor has signed in under within the Visitor Book page.</p>
                                        </div>

                                        <div className="flex-row">
                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                <div className="label">Visitor Badge Colour</div>
                                                <select value={typedLocation.BadgeColor} style={{ backgroundColor: typedLocation.BadgeColor }} onChange={ev => setTypedLocation({...typedLocation, BadgeColor: ev.target.value })} >
                                                    {colors.map((n, index) => (
                                                        <option key={index.toString()} style={{ backgroundColor: n.color }} label={n.name} value={n.color}>{n.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <p className="flex-fill flex-align-self-center margin-left-15">Select a colour for the Visitor Badge that appears for your visitor once they have signed in. This will help highlight which visitor type your visitor has signed in under when they show their Visitor Badge.</p>
                                        </div>
                                        
                                        <div className="flex-row">
                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                <CheckBox label="Pre Registration" checked={typedLocation.PreRegistration} onChecked={c => setTypedLocation({...typedLocation, PreRegistration: c })} />
                                            </div>
                                            <p className="flex-fill flex-align-self-center margin-left-15">Select "Pre Registration" to generate a unique QR code that can be sent to Visitors to pre register via the TAAP Visitor Book App only.</p>
                                        </div>

                                        {typedLocation.PreRegistration && (
                                            <div>
                                                <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                    <button className="btn" onClick={onDownloadPreRegistrationQRCode}>Download/Copy Pre-Registration QR Code</button>
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex-row">
                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                <CheckBox label="Email Verification" checked={typedLocation.EmailVerification} onChecked={c => setTypedLocation({...typedLocation, EmailVerification: c })} />
                                            </div>
                                            <p className="flex-fill flex-align-self-center margin-left-15">Select "Email Verification" to request your visitors to verify their email address before signing in.</p>
                                        </div>
                                    </div>

                                    <h1>Visitor Type Workflow Rules</h1>
                                    <div className="bordered-container">
                                        <h2 className="no-margin-top">Upload Email Addresses</h2>
                                        <p>Upload a CSV file containing a single column of only email addresses for visitors that need to be mapped to this Visitor Type.</p>
                                        {uploadingCSV ? <Loading margin="15px" /> : 
                                            <>
                                                <div className="form-control">
                                                    <div className="label">Upload</div>
                                                    <input type="file" accept=".csv" onChange={onEmailCSVSelected} />
                                                </div>
                                                <button className="btn" onClick={onClearEmails}>CLEAR ALL EMAILS</button>
                                                {typedLocationAutoConvertEmailsLength > 0 ? <h3 className="no-margin-bottom"><span className="text-bold" style={{ color: "green" }}>{typedLocationAutoConvertEmailsLength}</span> email addresses are currently associated with this Visitor Type. See the 'Email Addresses' tab on the main location page for more details.</h3> : ""}
                                            </>
                                        }
                                    </div>
                                    <div className="flex-row">
                                        <div className="flex-fill-45" style={{ position: "relative" }}>
                                            <div className="bordered-container margin-top-15" style={{ height: "100%" }}>
                                                <h1 className="no-margin-bottom no-margin-top">On Sign In</h1>
                                                <p>You can automatically move all visitors to a new Visitor Type on their next sign in.</p>
                                                <div className="flex-row">
                                                    <div className="form-control">
                                                        <div className="label">On Next Sign in Move Visitor to:</div>
                                                        <select value={(typedLocation.AutoConvertToVisitorTypeId || "").toString()} onChange={ev => setTypedLocation({
                                                            ...typedLocation, 
                                                            AutoConvertToVisitorTypeId: Number(ev.target.value),
                                                            AutoConvertToVisitorType: typedLocationOptions.find(o => o.Id === Number(ev.target.value))?.VisitorTypeName})} >

                                                            <option key="0" label="Don't Convert" value="">Don't Convert</option>
                                                            {typedLocationOptions.filter(n => n.Id !== typedLocation.Id).map(n => (
                                                                <option key={n.Id.toString()} label={n.VisitorTypeName} value={n.Id.toString()}>{n.VisitorTypeName}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {typedLocation.AutoConvertToVisitorTypeId ? (
                                                    <div style={{ color: "green", fontSize: "1.6rem" }}>Your visitor will automatically move from <span className="text-bold">{typedLocation.VisitorTypeName}</span> Visitor Type to <span className="text-bold">{typedLocation.AutoConvertToVisitorType}</span> Visitor Type on their next sign in.</div>
                                                ) : ""}
                                            </div>
                                        </div>
                                        <div className="flex-fill-10 flex-align-self-center">
                                            {!typedLocation.AutoConvertToVisitorTypeId ? <h1 className="text-center">OR</h1> : null}
                                        </div>
                                        <div className="flex-fill-45">

                                            {!typedLocation.AutoConvertToVisitorTypeId ?
                                                <>
                                                    <div className="bordered-container margin-top-15">
                                                        <h1 className="no-margin-bottom no-margin-top">On Expiry</h1>
                                                        <p className="flex-fill flex-align-self-center">Select the number of days your visitors should remain in the {typedLocation.VisitorTypeName} Visitor Type.</p>
                                                        <div className="flex-row">
                                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                                <div className="label">Expire in (Days):</div>
                                                                <input type="number" min="1" value={(typedLocation.AutoConvertToVisitorTypeExpiryDays || "")} onChange={ev => setTypedLocation({...typedLocation, AutoConvertToVisitorTypeExpiryDays: Number(ev.target.value)})}></input>
                                                            </div>
                                                        </div>
                                                
                                                        {typedLocation.AutoConvertToVisitorTypeExpiryDays && typedLocation.AutoConvertToVisitorTypeExpiryDays > 0 ? 
                                                        <div className="flex-row">
                                                            <div className="form-control" style={{ width: "250px", minWidth: "250px" }}>
                                                                <div className="label">On Expiry Move Visitor to:</div>
                                                                <select value={(typedLocation.AutoConvertToVisitorTypeMoveOnExpiryId || "").toString()} onChange={ev => setTypedLocation({
                                                                    ...typedLocation, 
                                                                    AutoConvertToVisitorTypeMoveOnExpiryId: Number(ev.target.value), 
                                                                    AutoConvertToVisitorTypeMoveOnExpiry: typedLocationOptions.find(o => o.Id === Number(ev.target.value))?.VisitorTypeName })} >

                                                                    <option key="0" label={location.VisitorTypeName} value="">{location.VisitorTypeName}</option>
                                                                    {typedLocationOptions.filter(n => n.Id !== typedLocation.Id && n.ParentLocationId).map(n => (
                                                                        <option key={n.Id.toString()} label={n.VisitorTypeName} value={n.Id.toString()}>{n.VisitorTypeName}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div> : null}

                                                        {typedLocation.AutoConvertToVisitorTypeExpiryDays ? (
                                                            <div style={{ color: "green", fontSize: "1.6rem" }}><span> Visitors will remain in the <span className="text-bold">{typedLocation.VisitorTypeName}</span> Visitor Type for <span className="text-bold">{typedLocation.AutoConvertToVisitorTypeExpiryDays}</span> Days, at which point they will then move to the <span className="text-bold">{typedLocation.AutoConvertToVisitorTypeMoveOnExpiryId ? typedLocation.AutoConvertToVisitorTypeMoveOnExpiry : location.VisitorTypeName}</span> Visitor Type.</span></div>
                                                        ) : ""}
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                    </div>

                                    <div className="flex-row margin-top-15">
                                        <button className={`btn margin-top-15${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                                        <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessage.success ? saveMessageStyles.success : saveMessageStyles.error}`}>{saveMessage.message}</span>
                                        <div className="flex-fill"></div>
                                        {typedLocation.ParentLocationId && <div className="margin-left-15">
                                            <button className="btn margin-top-15 red-button" onClick={onDeleteTypedLocation}>DELETE</button>
                                        </div>}
                                    </div>
                                </>
                            )
                        },
                        {
                            label: `Manage Visitors for ${typedLocation.VisitorTypeName}`,
                            component: (
                                <>
                                    <h1>Manage Visitors</h1>
                                    <h2>{typedLocation.Name} - {typedLocation.VisitorTypeName}</h2>
                                    <div className="flex-row">
                                        <div className="flex-fill-33">
                                            <h2>Visitors in "{typedLocation.VisitorTypeName}"</h2>
                                            <p>Select visitors to remove or move to a new Visitor Type.</p>
                                            <div className="form-control">
                                                <div className="label">Search</div>
                                                <input type="text" value={searchInVisitorType} onChange={ev => onSearchInVisitorType(ev.target.value)} />
                                            </div>

                                            <button className="btn margin-right-15" onClick={() => {
                                                setLocationIdToMoveTo(undefined);
                                                setMoveModalOpen(true);
                                            }}>MOVE</button>
                                            
                                            <button className="btn" onClick={() => onRemoveFromVisitorType()}>REMOVE</button>
                                            
                                            <table className="table table-shaded-header">
                                                <thead>
                                                <tr>
                                                    <th style={{width: "100px"}}>
                                                        Select All
                                                        <CheckBox label="" style={{ marginBottom: "0px", marginTop: "0px" }} checked={allTicked} onChecked={c => { setAllTicked(c); setFilteredMappings(filteredMappings.map(m => ({...m,Ticked: c}))); }} />
                                                    </th>
                                                    <th>Name</th>
                                                    <th>Expiry Date</th>
                                                    <th>Move On Expiry</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {renderedVisitorTypes}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="margin-left-15 flex-fill-33">
                                            <Tabs tabs={[
                                                {
                                                    label: "All Visitors",
                                                    component: (
                                                        <>
                                                            <h2>All Visitors</h2>
                                                            <p>Select ADD on a visitor to add them to Visitor Type "{typedLocation.VisitorTypeName}"</p>
                                                            <div className="flex-row margin-bottom-15">
                                                                <div className="form-control flex-fill no-margin-bottom no-margin-top">
                                                                    <div className="label">Search</div>
                                                                    <input type="text" value={search} onChange={ev => setSearch(ev.target.value)} />
                                                                </div>
                                                                <button className={`btn margin-left-15 flex-align-self-end${searchingVisitors ? " loading" : ""}`} onClick={onSearch}>SEARCH</button>
                                                            </div>
                                                            <table className="table table-shaded-header">
                                                                <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Sign in Date / Time</th>
                                                                    <th>Signed In As</th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {renderedVisitors}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )
                                                },
                                                {
                                                    label: "Client Registered Users",
                                                    component: (
                                                        <>
                                                            <h2>Client Registered Users</h2>
                                                            <p>Select ADD on a client registered user to add them to Visitor Type "{typedLocation.VisitorTypeName}"</p>
                                                            <div className="flex-row margin-bottom-15">
                                                                <div className="form-control flex-fill no-margin-bottom no-margin-top">
                                                                    <div className="label">Search</div>
                                                                    <input type="text" value={CRUSearch} onChange={ev => setCRUSearch(ev.target.value)} />
                                                                </div>
                                                                <button className={`btn margin-left-15 flex-align-self-end${searchingCRUs ? " loading" : ""}`} onClick={onCRUSearch}>SEARCH</button>
                                                            </div>
                                                            <table className="table table-shaded-header">
                                                                <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                    <th>Category</th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {renderedCRUs}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )
                                                }
                                            ]} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    ]} />
                </div>

                {moveModalOpen && <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Move To Visitor Type</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setMoveModalOpen(false)}>&#10006;</button>
                    </div>

                    <div className="form-control no-margin-bottom">
                        <div className="label">Move To</div>
                        <select value={locationIdToMoveTo} onChange={ev => setLocationIdToMoveTo(Number(ev.target.value))} >
                            <option key="0" label="Select.." value="">Select..</option>
                            {typedLocationOptions.filter(n => n.Id !== typedLocation.Id).map(n => (
                                <option key={n.Id.toString()} label={n.VisitorTypeName} value={n.Id.toString()}>{n.VisitorTypeName}</option>
                            ))}
                        </select>
                    </div>

                    <div className="text-center margin-top-15">
                        <button className="btn" onClick={onMoveVisitorTypes}>MOVE</button>
                    </div>
                    
                </Modal>}

                {addModalOpen && <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add To "{typedLocation.VisitorTypeName}"</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setAddModalOpen(false)}>&#10006;</button>
                    </div>
                    <p>This will add {selectedVisitorToAdd?.VisitorName} to {typedLocation.VisitorTypeName}</p>
                    {typedLocation.AutoConvertToVisitorTypeExpiryDays && !typedLocation.AutoConvertToVisitorTypeId && 
                        <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                            <div className="label">Expiry Date</div>
                            <DatePicker selected={expiryDate} onChange={d => setExpiryDate(d as any)} dateFormat="dd/MM/yyyy" />
                        </div>
                    }

                    <div className="text-center margin-top-15">
                        <button className="btn" onClick={onAddVisitorTypeMapping}>ADD</button>
                    </div>
                    
                </Modal>}

                {addCRUModalOpen && <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add To "{typedLocation.VisitorTypeName}"</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setAddCRUModalOpen(false)}>&#10006;</button>
                    </div>
                    <p>This will add {selectedCRUToAdd?.Name} to {typedLocation.VisitorTypeName}</p>
                    {typedLocation.AutoConvertToVisitorTypeExpiryDays && !typedLocation.AutoConvertToVisitorTypeId && 
                        <div className="form-control flex-align-self-end no-margin-bottom margin-right-15" style={{ maxWidth: "250px" }}>
                            <div className="label">Expiry Date</div>
                            <DatePicker selected={expiryDate} onChange={d => setExpiryDate(d as any)} dateFormat="dd/MM/yyyy" />
                        </div>
                    }

                    <div className="text-center margin-top-15">
                        <button className="btn" onClick={onAddCRUTypeMapping}>ADD</button>
                    </div>
                    
                </Modal>}

                {dlqrModalOpen && (
                    <Modal maxWidth="1024px">

                        <div className="flex-row">
                            <div className="flex-title">Pre Registration QR Code for "{typedLocation.VisitorTypeName}"</div>
                            <div className="flex-fill"></div>
                            <button className="btn" onClick={_ => setDlqrAddModalOpen(false)}>&#10006;</button>
                        </div>

                        <h3 className="margin-bottom-15 margin-top-15" style={{fontSize: "24px"}}>Please download the QR code OR copy and paste the below into an email to send to your Visitors for pre registration.</h3>
                        <button className="btn margin-bottom-15" onClick={() => downloadBase64String(`PreRegistrationQrCode-${location.Name}-${typedLocation.VisitorTypeName}.png`, preRegQRImageString, "image/png")}>Download QR Code Image</button>
                        
                        <hr />

                        <p style={{fontSize: "20px"}}>Please follow the below instructions to pre register before attending site.</p>
                        <p style={{fontSize: "20px"}}>You will need to install the TAAP Visitor Book App on the device you will be attending site with.</p>

                        <p style={{fontSize: "20px"}}>
                            <a style={{height: "40px", width: "134px"}} href="https://play.google.com/store/apps/details?id=com.ontaap.visitorbook&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" height="40" width="134" src={`${window.location.origin}/addin/assets/play_store_en_badge_web_generic.png`}/></a>
                            &nbsp;
                            <a style={{height: "40px", width: "auto"}} href="https://apps.apple.com/us/app/taap-visitor-book/id1505798503?ls=1"><img alt="Get it on the App Store" height="40" src={`${window.location.origin}/addin/assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png`}/></a>
                        </p>

                        <p>
                            <a style={{fontSize: "20px"}} href="https://ontaap.com/visitorbook">What is Visitor Book? Click to find out more</a>
                        </p>

                        <p style={{fontSize: "20px"}}>Once downloaded please scan the QR code below to begin your pre-registration process.</p>

                        <img alt="QR Code" src={preRegQRImageString} width={256} height={256} style={{ width: "256px", height: "256px" }} />
                    
                        <p style={{fontSize: "20px"}}>
                            If you have this email open on your phone then please click the link below to begin the pre-registration process.
                            <br/>
                            <a style={{fontSize: "20px"}} href={preRegQRUrl}>Register</a>
                        </p>
                    </Modal>
                )}
            </>
        );

    return null;
};