import axios, { AxiosResponse } from 'axios';

export const apiUrl = (route: string): string => {
    if(!route.startsWith("/"))
        route = "/" + route;

    let baseUrl = process.env.REACT_APP_API_URL;

    if(baseUrl?.endsWith("/"))
        baseUrl = baseUrl.replace(/\/+$/g, "");

    return baseUrl + route;
};

export class UnauthorizedError extends Error {

    status: number = 401;

    constructor(message?: string){
        super(message || "Unauthorised");
    }
}

export const req = async (method: string, url: string, body: any, bearerTokenName: string | null): Promise<any> => {

    let res: AxiosResponse<any>;
    try{
        const headers: any = {
            "Content-Type": "application/json"
        };

        if(bearerTokenName) {
            const token = localStorage.getItem(bearerTokenName);
            if(token) {
                headers["Authorization"] = `Bearer ${token}`;
            }
        }

        res = await axios.request({
            method: method as any,
            url: url,
            //withCredentials: true,
            data: body,
            headers
        });

        let resBody = res.data;

        if(resBody){
            return resBody;
        }
        else {
            return;
        }
    }
    catch(err: any) {
        let resBody = err.response.data;

        if(err.response.status !== 200){
            // Error
            let message: string | undefined = undefined;
            if(resBody) {
                message = resBody.message;
            }

            if(err.response.status === 401) {
                throw new UnauthorizedError(message);
            }

            throw Error(message || "An unknown error occured");
        }

        throw new Error("An unknown error occured");
    }
};

export const blobReq = async (method: string, url: string, body: any, bearerTokenName: string | null): Promise<Blob | undefined> => {
    let res: AxiosResponse<Blob>;
    try{
        const headers: any = {
            "Content-Type": "application/json"
        };

        if(bearerTokenName) {
            const token = localStorage.getItem(bearerTokenName);
            if(token) {
                headers["Authorization"] = `Bearer ${token}`;
            }
        }

        res = await axios.request<Blob>({
            method: method as any,
            url: url,
            //withCredentials: true,
            data: body,
            headers,
            responseType: "blob"
        });

        let resBody = res.data;

        if(resBody){
            return resBody;
        }
        else {
            return undefined;
        }
    }
    catch(err: any) {
        let resBody = err.response.data;

        if(err.response.status !== 200){
            // Error
            let message: string | undefined = undefined;
            if(resBody) {
                message = resBody.message;
            }

            if(err.response.status === 401) {
                throw new UnauthorizedError(message);
            }

            throw Error(message || "An unknown error occured");
        }

        throw new Error("An unknown error occured");
    }
};