export const downloadBase64String = (fileName: string, base64String: string, mimeType: string) => {
    const byteString = atob(base64String.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    downloadBlob(fileName, blob);
};

export const downloadBlob = (fileName: string, blob: Blob) => {
    if((window as any).navigator.msSaveOrOpenBlob) {
        (window.navigator as any).msSaveBlob(blob, fileName);
        return;
    }

    const a  = document.createElement('a');
    a.style.display = "none";
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function() { window.URL.revokeObjectURL(objectUrl); }, 40000); // 40s
};