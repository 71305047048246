import React from 'react';
import styles from './CheckBox.module.css';

type Props = {
    label: string,
    checked: boolean,
    onChecked: (checked: boolean) => void,
    sideLabel?: boolean,
    className?: string,
    style?: React.CSSProperties
};

export const CheckBox: React.FC<Props> = ({ label, checked, onChecked, sideLabel, style, className }) => {
    return (
        <div className={`form-control${className ? " " + className : ""}`} style={style}>
            {!sideLabel && <div className="label">{label}</div>}
            <div className={styles.checkbox} onClick={_ => onChecked(!checked)}>
                <div className={`${styles.checkboxContent}${checked ? " " + styles.checkboxChecked : ""}`}></div>
            </div>
            {sideLabel && (
                <div className={styles.sideLabel}>
                    <div className={styles.sideLabelContent}>{label}</div>
                </div>
            )}
        </div>
    );
};