import { req, apiUrl } from "./helpers";

export const USER_TOKEN_NAME = "usertok";
export const SYSTEM_ADMIN_TOKEN_NAME = "systok";

export const login = async (Email: string, Password: string): Promise<AuthCheckResult> => {
    const result: AuthCheckResultWithToken = await  req("POST", apiUrl("/api/login"), { Email, Password }, null);

    localStorage.setItem(USER_TOKEN_NAME, result.Token);

    return result;
};

export const logout = async (): Promise<any> => {
    //return req("GET", apiUrl("/api/logout"));
    localStorage.removeItem(USER_TOKEN_NAME);
};

export type AuthCheckResult = {
    DisplayName: string,
    DefaultLocationId: number,
    Role: string,
    ClientName: string,
    HelpUrl: string,
    PrivacyUrl: string,
    SupportUrl: string,
    AdminWelcome: boolean,
    ClientDemo: boolean
};

type AuthCheckResultWithToken = AuthCheckResult & { Token: string };

export const authCheck = async (): Promise<AuthCheckResult> => {
    return req("GET", apiUrl("/api/authcheck"), null, USER_TOKEN_NAME);
};

// System Admin

export const systemAdminLogin = async (Email: string, Password: string): Promise<SystemAdminAuthResult> => {
    const result: SystemAdminAuthResultWithToken = await req("POST", apiUrl("/api/systemadminlogin"), { Email, Password }, null);

    localStorage.setItem(SYSTEM_ADMIN_TOKEN_NAME, result.Token);

    return result;
};

export const systemAdminLogout = async (): Promise<any> => {
    //return req("GET", apiUrl("/api/systemadminlogout"));
    localStorage.removeItem(SYSTEM_ADMIN_TOKEN_NAME);
};

export type SystemAdminAuthResult = {
    DisplayName: string,
    ClientName: string
};

type SystemAdminAuthResultWithToken = SystemAdminAuthResult & { Token: string };

export const systemAdminAuthCheck = async (): Promise<SystemAdminAuthResult> => {
    return req("GET", apiUrl("/api/systemadminauthcheck"), null, SYSTEM_ADMIN_TOKEN_NAME);
};