import React, { useState } from 'react';
import { CreateClientRequest, systemAdminCreateClient } from '../../api/system-admin';
import { CheckBox } from '../../components/CheckBox/CheckBox';
import { systemAdminLogout } from '../../api/auth';
import { useHistory } from 'react-router-dom';

export const SystemAdminHome: React.FC = () => {

    const history = useHistory();
    const [loggingOut, setLoggingOut] = useState<boolean>(false);

    const [saving, setSaving] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [created, setCreated] = useState<boolean>(false);
    const [client, setClient] = useState<CreateClientRequest>({
        ClientName: "",
        Domain: "",
        LocationName: "",
        AdminUserEmail: "",
        AdminUserPassword: "",
        MaxLocations: 1,
        Demo: false
    });

    const onLogout = async () => {
        setLoggingOut(true);
        await systemAdminLogout();
        history.push("/");
    };

    const onSave = async () => {
        setSaving(true);

        try {
            await systemAdminCreateClient(client);
            setSaving(false);
            setError("");
            setCreated(true);
        }
        catch(err: any) {
            setSaving(false);
            setError(err.message);
        }
    };

    const toNum = (input: string): number => {
        const num = Number(input);
        if(!isNaN(num) && num)
            return num;

        return client.MaxLocations || 1;
    };

    return (
        <div className="container-skinny">
            <button className={`btn${loggingOut ? " loading" : ""}`} onClick={onLogout}>LOG OUT</button>
            <h1>Add Client</h1>

            {!created && (
                <>
                    <div className="form-control">
                        <div className="label">Client Name</div>
                        <input type="text" value={client.ClientName} onChange={ev => setClient({...client, ClientName: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Add-In email Domain (e.g example.com)</div>
                        <input type="text" value={client.Domain} onChange={ev => setClient({...client, Domain: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Initial Location Name</div>
                        <input type="text" value={client.LocationName} onChange={ev => setClient({...client, LocationName: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Max Locations</div>
                        <input type="tel" pattern="[0-9]*" value={client.MaxLocations} onChange={ev => setClient({...client, MaxLocations: toNum(ev.target.value)})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Admin User Email</div>
                        <input type="text" value={client.AdminUserEmail} onChange={ev => setClient({...client, AdminUserEmail: ev.target.value})} />
                    </div>

                    <div className="flex-row">
                        <div className="form-control flex-fill">
                            <div className="label">Admin User Password</div>
                            <input type={showPass ? "text" : "password"} value={client.AdminUserPassword} onChange={ev => setClient({...client, AdminUserPassword: ev.target.value})} />
                        </div>

                        <div className="margin-left-15 margin-right-15">
                            <CheckBox label="Show" checked={showPass} onChecked={c => setShowPass(c)} />
                        </div>
                    </div>
                    
                    <CheckBox label="Demo Client" checked={client.Demo} onChecked={c => setClient({...client, Demo: c})} />
                    
                    <button className={`btn${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>

                    <div className="error-message">{error}</div>
                </>
            )}

            {created && (
                <div className="success-message">Client created successfully!</div>
            )}
        </div>
    );
};