export const prettyLocalTime = (utcDateTimeString?: string): string => {
    if(!utcDateTimeString)
			return "";
	
    const d = new Date(utcDateTimeString);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
    return `${pad(d.getHours())}:${pad(d.getMinutes())}`;
}

export const prettyLocalDate = (utcDateTimeString?: string): string => {
    if(!utcDateTimeString)
			return "";
	
    const d = new Date(utcDateTimeString);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
    return `${pad(d.getDate())}/${pad(d.getMonth()+1)}/${d.getFullYear()}`;
}

export const prettyLocalDateTime = (utcDateTimeString?: string): string => {
    if(!utcDateTimeString)
			return "";
	
    const d = new Date(utcDateTimeString);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
    return `${pad(d.getDate())}/${pad(d.getMonth()+1)}/${d.getFullYear()} ${pad(d.getHours())}:${pad(d.getMinutes())}`;
}


const pad = (n: number): string => {
    return ("00" + n).slice(-2);
}

export const getUTCDateString = (date: Date) => {
    return `${date.getUTCFullYear()}-${pad(date.getUTCMonth()+1)}-${pad(date.getUTCDate())}`;
};

export const readableNow = (): string => {
    const d = new Date();
    const months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${pad(d.getDate())} ${months[d.getMonth()]} ${d.getFullYear()}`;
}