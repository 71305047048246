import { req, apiUrl, blobReq } from "./helpers";
import { USER_TOKEN_NAME } from "./auth";

export type Visitor = {
    Id: number;
    ArrivalDateTime: string,
    DepartureDateTime: string;
    Visiting: string;
    HostEmailed: boolean;
    HostNotified: string;
    VisitorName: string;
    VisitorPhone: string;
    VisitorEmail: string;
    VehicleRegistration: string;
    VisitorPhotoUrl: string;
    VisitorCompany?: string;
    VisitingCompany?: string;
    BadgeNumber: string;
    Postcode: string;
    MilesTravelled: string;
    ModeOfTransport: string;
    DriverOfTheVehicle: string;
    FuelType: string;
    SpecialAssistance: string;
    RowColor: string;
    VisitorTypeName: string;
    BadgeNumberShown: boolean;
};

// export type AddVisitorData = {
//     OrganiserName: string;
//     VisitorName: string;
//     VisitorPhone: string;
//     VisitorEmail: string;
//     VehicleRegistration: string;
//     VisitorPhoto: string;
//     VisitorPhotoFileName: string;
//     VisitorPhotoContentType: string;
//     VisitorCompany?: string;
//     VisitingCompany?: string;
//     SpecialAssistance: string;
//     CheckInCode: string;
//     Validate: boolean;
// };

export type PagedResultInfo = {
    Count: number,
    PageCount: number
};

export type PagedVisitor = Visitor & {
    HoursOnSite: string
};

export type PagedVisitorResult = {
    Result: PagedVisitor[],
    Info: PagedResultInfo
};

export const getVisitors = (LocationId: number): Promise<Visitor[]> => {
    return req("POST", apiUrl("/api/GetTodaysVisitors"), { LocationId }, USER_TOKEN_NAME);
};

export type GetPagedVisitorsRequest = {
    LocationId?: number,
    DateFrom?: string,
    DateTo?: string,
    VisitorName?: string,
    PageSize: number,
    PageNumber: number
};

export type ExportVisitorsRequest = {
    LocationId?: number,
    DateFrom?: string,
    DateTo?: string,
    VisitorName?: string
};

export const getPagedVisitors = (pagedVisitorsRequest: GetPagedVisitorsRequest): Promise<PagedVisitorResult> => {
    return req("POST", apiUrl("/api/GetPagedVisitors"), pagedVisitorsRequest, USER_TOKEN_NAME);
};

export const signOutVisitor = (Id: number): Promise<any> => {
    return req("POST", apiUrl("/api/SignOutVisitor"), { Id }, USER_TOKEN_NAME);
};

// export const addVisitor = (addVisitorData: AddVisitorData): Promise<any> => {
//     return req("POST", apiUrl("/api/Submit"), {...addVisitorData, MeetingGUID: "", FromPortal: true }, USER_TOKEN_NAME);
// };

export const markVisitorAsNotified = (VisitorId: number): Promise<any> => {
    return req("POST", apiUrl("/api/MarkVisitorAsNotified"), { VisitorId }, USER_TOKEN_NAME);
};

export const deleteVisitor = (VisitorId: number) => {
    return req("POST", apiUrl("/api/DeleteVisitor"), { VisitorId }, USER_TOKEN_NAME);
};

export const exportVisitors = (exportVisitorsRequest: ExportVisitorsRequest): Promise<Blob | undefined> => {
    return blobReq("POST", apiUrl("/api/ExportVisitors"), exportVisitorsRequest, USER_TOKEN_NAME);
};

export const updateVisitorBadgeNumber = (VisitorId: number, BadgeNumber: string) => {
    return req("POST", apiUrl("/api/UpdateVisitorBadgeNumber"), { VisitorId, BadgeNumber }, USER_TOKEN_NAME);
};

export type VisitorWithMapping = {
    Id: number,
    ArrivalDateTime: string,
    LocationName: string,
    VisitorName: string,
    DeviceGUID: string,
    VisitorTypeName: string,
    RowColor: string
};

export const getVisitorsWithMappings = (ParentLocationId: number, Search: string): Promise<VisitorWithMapping[]> => {
    return req("POST", apiUrl("/api/GetVisitorsWithMappings"), { ParentLocationId, Search }, USER_TOKEN_NAME);
};