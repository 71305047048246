import { req, apiUrl } from "./helpers";
import { SYSTEM_ADMIN_TOKEN_NAME } from "./auth";

export type CreateClientRequest = {
    ClientName: string,
    Domain: string,
    LocationName: string,
    AdminUserEmail: string,
    AdminUserPassword: string,
    MaxLocations: number,
    Demo: boolean
};

export const systemAdminCreateClient = (client: CreateClientRequest) => {
    return req("POST", apiUrl("/api/SystemAdminCreateClient"), client, SYSTEM_ADMIN_TOKEN_NAME);
};