import React from 'react';
import styles from './MessageBox.module.css';
import { useMessageBoxState } from '../../contexts/message-box.context';

// export const openMessageBox = (title: string, message: string, mbOptions?: Partial<MessageBoxOptionsOptions>) : Promise<boolean> => {
//     return new Promise((resolve) => {
//         const options = mbOptions || {};
        
//         setGlobalState({
//             messageBoxOptions: {
//                 open: true,
//                 title,
//                 message,
//                 callback: (okOrCancel: boolean) => resolve(okOrCancel),
//                 options: {
//                     hasCancel: options.hasCancel || false,
//                     redOkayButton: options.redOkayButton || false
//                 }
//             }
//         });
//     });
// }

export const MessageBox: React.FC = () => {

    const { messageBoxState, setMessageBoxState } = useMessageBoxState();

    if(!messageBoxState.open)
        return null;

    const close = async (okOrCancel: boolean) => {

        const callback = messageBoxState.callback;

        setMessageBoxState({
            open: false,
            title: "",
            message: "",
            options: {
                hasCancel: false,
                redOkayButton: false
            },
            callback: undefined
        });

        if(callback)
            callback(okOrCancel);
    }
    
    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <div className={styles.content}>
                    <div className="flex-row">
                        <div className="flex-title">{messageBoxState.title}</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => close(false)}>&#10006;</button>
                    </div>
                    <p>{messageBoxState.message}</p>

                    <div className={`flex-row ${styles.footer}`} style={{ justifyContent: messageBoxState.options.hasCancel ? "space-between": "space-around" }}>
                        <button className={`btn${messageBoxState.options.redOkayButton ? " red-button" : ""}`} onClick={_=>close(true)}>{messageBoxState.okayButtonText || "OK"}</button>
                        {messageBoxState.options.hasCancel && <button className="btn" onClick={_=>close(false)}>{messageBoxState.cancelButtonText || "CANCEL"}</button>}
                    </div>
                </div>
            </div>
        </div>
    );
}