import * as QRCode from 'qrcode';

export const printLocationQRCode = (type: string, qrContent: string, iframeRef: HTMLIFrameElement | null | undefined, demo?: boolean) => {
    if(!iframeRef)
        return;
    
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.width = 512;
    canvas.height = 512;
    QRCode.toCanvas(canvas, qrContent, { errorCorrectionLevel: "H", width: 512 }, (error: Error) => {
        if(error){
            console.error(error);
            return;
        }

        if(!iframeRef)
            return;

        let oWin = iframeRef.contentWindow;
        let oDoc = iframeRef.contentDocument || oWin?.document;
        
        if(!oDoc || !oWin)
            return;
        
        oDoc.write(`
            <!DOCTYPE HTML>
            <html lang="en" style="font-family: Helvetica, Arial, sans-serif;" moznomarginboxes mozdisallowselectionprint>
                <head>
                    <style type="text/css" media="print">
                        @page {
                            size: auto;   /* auto is the initial value */
                            margin: 0;  /* this affects the margin in the printer settings */
                        }
                    </style>

                    <title>TAAP Here Visitor Book</title>
                <head>

                <body style="font-family: Helvetica, Arial, sans-serif;">
                    <div style="text-align: center;">    
                        <h1 style="color: #e60000;">${type === "SignIn" ? "SCAN TO SIGN IN" : "SCAN TO SIGN OUT"}</h1>

                        <div>
                            <img id="logo" width="300" height="144" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=" />
                        </div>
                        <div>
                            <img id="qrcode" width="384" height="384" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=" />
                        </div>
                        <h1 style="color: #006adb;">CAN'T SCAN?</h1>
                        <h2 style="text-decoration: underline; color: #0075f2;">https://visitorbook.taapstore.com/meeting</h2>
                        ${demo ? `<h1 style="color: #000000;">DEMO</h1>` : ""}
                    </div>
                </body>
            </html>
        `);
        oDoc.close();

        const img = oDoc.getElementById("qrcode") as HTMLImageElement;
        img.onload = () => {
            if(!oDoc || !oWin)
                return;

            const logoImg = oDoc.getElementById("logo") as HTMLImageElement;
            if(!logoImg)
                return;

            logoImg.onload = () => {
                if(!oDoc || !oWin)
                    return;

                oWin.print();
            };
            logoImg.src = `${window.location.origin}/assets/TAAPHere.png`;
            
        };
        img.src = canvas.toDataURL("image/png");
    });
}

export const  getQRCodePNG = (qrContent: string): Promise<string> => {
    return new Promise<string>((resolve) => {
        const canvas = document.createElement("canvas") as HTMLCanvasElement;
        canvas.width = 512;
        canvas.height = 512;
        QRCode.toCanvas(canvas, qrContent, { errorCorrectionLevel: "H", width: 512 }, (error: Error) => {
            const imgString = canvas.toDataURL("image/png")
            resolve(imgString);     
        });
    });
}