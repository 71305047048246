import React, { createContext, useReducer, useContext, PropsWithChildren } from 'react';
import { AuthCheckResult } from '../api/auth';

type UserState = {
    isLoggedIn: boolean,
    data: AuthCheckResult
};

const initialState: UserState = {
    isLoggedIn: false,
    data: {
        DisplayName: "",
        DefaultLocationId: 0,
        Role: "",
        ClientName: "",
        HelpUrl: "",
        PrivacyUrl: "",
        SupportUrl: "",
        AdminWelcome: true,
        ClientDemo: false
    }
};

const UserStateContext = createContext<any>({});

const userStateReducer = (state: any, action: any): UserState => {
    return {...state, ...action};
};

export const UserStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(userStateReducer, initialState);

    return (
        <UserStateContext.Provider value={[state, dispatch]}>
            {children}
        </UserStateContext.Provider>
    );
};

export const useUserState = (): { userState: UserState, setUserState: (updates: Partial<UserState>) => void } => {
    const [userState, dispatch] = useContext(UserStateContext);

    const setUserState = (updates: Partial<UserState>) => {
        dispatch(updates);
    };

    return { userState, setUserState };
};