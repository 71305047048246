import { USER_TOKEN_NAME } from "./auth";
import { req, apiUrl } from "./helpers";

export type VisitorTypeMapping = {
    Id: number,
    DisplayName: string,
    ExpiryDate?: string,
    MoveOnExpiry?: string
};

export const getVisitorTypeMappings = (LocationId: number): Promise<VisitorTypeMapping[]> => {
    return req("POST", apiUrl("/api/GetVisitorTypeMappings"), { LocationId }, USER_TOKEN_NAME);
};

export const addVisitorTypeMapping = (DeviceGUID: string, LocationId: number, DisplayName: string, ExpiryDate?: string): Promise<VisitorTypeMapping[]> => {
    return req("POST", apiUrl("/api/AddVisitorTypeMapping"), { DeviceGUID, LocationId, DisplayName, ExpiryDate}, USER_TOKEN_NAME);
};

export const removeVisitorTypeMappings = (Ids: number[]) => {
    return req("POST", apiUrl("/api/RemoveVisitorTypeMappings"), { Ids }, USER_TOKEN_NAME);
};

export const moveVisitorTypes = (Ids: number[], LocationId: number) => {
    return req("POST", apiUrl("/api/MoveVisitorTypeMappings"), { Ids, LocationId }, USER_TOKEN_NAME);
};

