import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { VisitorBook } from './pages/VisitorBook/VisitorBook';
import { Login } from './pages/Login/Login';
import { AdminLocations } from './pages/AdminLocations/AdminLocations';
import { AdminLocation } from './pages/AdminLocation/AdminLocation';
import { UserStateProvider } from './contexts/user.context';
import { AuthGuard } from './components/AuthGuard/AuthGuard';
import { AdminUsers } from './pages/AdminUsers/AdminUsers';
import { AdminUser } from './pages/AdminUser/AdminUser';
import { MessageBoxStateProvider } from './contexts/message-box.context';
import { MessageBox } from './components/MessageBox/MessageBox';
import { Admin } from './pages/Admin/Admin';
import { Addin } from './pages/Addin/Addin';
import { PasswordReset } from './pages/PasswordReset/PasswordReset';
import { SystemAdminStateProvider } from './contexts/system-admin.context';
import { SystemAdminAuthGuard } from './components/SystemAdminAuthGuard/SystemAdminAuthGuard';
import { SystemAdminHome } from './pages/SystemAdminHome/SystemAdminHome';
import { SystemAdminLogin } from './pages/SystemAdminLogin/SystemAdminLogin';
import { AdminVisitorSearch } from './pages/AdminVisitorSearch/AdminVisitorSearch';
import { AdminLocationManage } from './pages/AdminLocationManage/AdminLocationManage';

function App() {
    return (
		<UserStateProvider>
			<MessageBoxStateProvider>
				<Router>
					<Switch>
						<Route exact={true} path="/login" component={Login}></Route>

						<Route exact={true} path="/passwordreset/:resetToken" component={PasswordReset}></Route>

						<Route exact={true} path="/" component={(_: any) => (
							<AuthGuard>
								<VisitorBook/>
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin" component={(_: any) => (
							<AuthGuard>
								<Admin />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/addin" component={(_: any) => (
							<AuthGuard>
								<Addin />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/visitors" component={(_: any) => (
							<AuthGuard>
								<AdminVisitorSearch />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/locations" component={(_: any) => (
							<AuthGuard>
								<AdminLocations />
							</AuthGuard>
						)}></Route>
						
						<Route exact={true} path="/admin/locations/:locationId" component={(props: any) => (
							<AuthGuard>
								<AdminLocation {...props} />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/locations/:locationId/manage/:subLocationId" component={(props: any) => (
							<AuthGuard>
								<AdminLocationManage {...props} />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/users" component={(_: any) => (
							<AuthGuard>
								<AdminUsers />
							</AuthGuard>
						)}></Route>

						<Route exact={true} path="/admin/users/:userId" component={(props: any) => (
							<AuthGuard>
								<AdminUser {...props} />
							</AuthGuard>
						)}></Route>

						<Route path="/systemadmin" component={(_: any) => (
							<SystemAdminStateProvider>
								<Switch>
									<Route exact path="/systemadmin/login" component={(_: any) => (
										<SystemAdminLogin />
									)}></Route>
									
									<Route exact path="/systemadmin/" component={(_: any) => (
										<SystemAdminAuthGuard>
											<SystemAdminHome />
										</SystemAdminAuthGuard>
									)}></Route>
								</Switch>
								
							</SystemAdminStateProvider>
						)} />
					</Switch>
				</Router>

				<MessageBox />
			</MessageBoxStateProvider>
		</UserStateProvider>
    );
}

export default App;
