import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './VisitorBook.module.css';
import { prettyLocalTime, readableNow } from '../../utils/date-time.utils';
import { getVisitors, Visitor, signOutVisitor, markVisitorAsNotified } from '../../api/visitors';
import { Loading } from '../../components/Loading/Loading';
import { getCode } from '../../api/code';
import { Menu } from '../../components/Menu/Menu';
import { getLocations, Location, deleteLocationVisitors } from '../../api/locations';
import { useMessageBoxState } from '../../contexts/message-box.context';
//import { Modal } from '../../components/Modal/Modal';
import { useUserState } from '../../contexts/user.context';
import { SignalRConnection, SignalREvent } from '../../services/signalr';
import { getQRCodePNG, printLocationQRCode } from '../../utils/print.utils';
import { UnauthorizedError } from '../../api/helpers';
import { useHistory } from 'react-router-dom';
import { boolToSymbol } from '../../utils/symbol.utils';
import { VisitorPanel } from '../../components/VisitorPanel/VisitorPanel';
import { CheckBox } from '../../components/CheckBox/CheckBox';

type FilterVisitorsResult = {
    filteredVisitors: Visitor[],
    selectedVisitorType: string,
    visitorTypeOptions: string[]
};

const filterVisitors = (visitorsToFilter: Visitor[], selectedVisitorType: string, searchString: string, onSiteOnly: boolean): FilterVisitorsResult => {
    if(!visitorsToFilter)
        return visitorsToFilter;

    let filtered: Visitor[] = visitorsToFilter;

    const visitorTypeOptions = visitorsToFilter.map(v => v.VisitorTypeName).filter((v, i, self) => self.indexOf(v) === i);
    if(!selectedVisitorType || visitorTypeOptions.indexOf(selectedVisitorType) === -1) {
        selectedVisitorType = "";
    }

    if(selectedVisitorType) {
        filtered = filtered.filter(v => v.VisitorTypeName === selectedVisitorType);
    }

    if(onSiteOnly)
        filtered = filtered.filter(v => !v.DepartureDateTime); 
    
    const timeKeys = ["ArrivalDateTime", "DepartureDateTime"];

    if(searchString.trim() !== "") {
        filtered = filtered.filter((v:any) => {
            for(const key in v) {
                if(timeKeys.includes(key) && v[key]) {
                    const time = prettyLocalTime(v[key]);
                    if(time.toLowerCase().includes(searchString.toLowerCase())) {
                        return true;
                    }
                }
                else {
                    if(typeof v[key] === "string" && (v[key] as string).toLowerCase().includes(searchString.toLowerCase())) {
                        return true;
                    }
                }
            }
    
            return false;
        });
    }

    return { filteredVisitors: filtered, selectedVisitorType, visitorTypeOptions };
}

function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
      function tick() {
          if(savedCallback.current)
            savedCallback.current();
      }
  
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

export const VisitorBook: React.FC = () => {

    const history = useHistory();
    const { userState } = useUserState();
    const { setMessageBoxState } = useMessageBoxState();
    const [visitors, setVisitors] = useState<Visitor[]>();
    const [filteredVisitors, setFilteredVisitors] = useState<Visitor[]>();
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [clearing, setClearing] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [locations, setLocations] = useState<Location[]>();
    const [location, setLocation] = useState<Location>();
    const [photoOpen, setPhotoOpen] = useState<boolean>(false);
    const [visitorOpen, setVisitorOpen] = useState<boolean>(false);
    const [expandedVisitor, setExpandedVisitor] = useState<Visitor>();
    const [photoViewerSrc, setPhotoViewerSrc] = useState<string>("");
    const [onSiteOnly, setOnSiteOnly] = useState<boolean>(false);
    const [visitorTypeOptions, setVisitorTypeOptions] = useState<string[]>([]);
    const [selectedVisitorTypeOption, setSelectedVisitorTypeOption] = useState<string>("");
    // const [addVisitorModalOpen, setAddVisitorModalOpen] = useState<boolean>(false);
    // const [addVisitorData, setAddVisitorData] = useState<AddVisitorData>({
    //     OrganiserName: "",
    //     VisitorName: "",
    //     VisitorPhone: "",
    //     VisitorEmail: "",
    //     VehicleRegistration: "",
    //     VisitorPhoto: "",
    //     VisitorPhotoFileName: "",
    //     VisitorPhotoContentType: "",
    //     VisitingCompany: "",
    //     VisitorCompany: "",
    //     SpecialAssistance: "",
    //     CheckInCode: "",
    //     Validate: true
    // });
    // const addVisitorPhotoRef = useRef<HTMLInputElement | null>();
    // const [addingVisitor, setAddingVisitor] = useState<boolean>(false);
    // const [addVisitorErrorMessage, setAddVisitorErrorMessage] = useState<string>("");
    const signalRConnection = useRef<SignalRConnection>();

    const filterVisitorsInternal: any = useCallback((visitorsToFilter: Visitor[], selectedVisitorType: string, searchString: string, onSiteOnly: boolean) => {
        const result = filterVisitors(visitorsToFilter, selectedVisitorType, searchString, onSiteOnly);
        setFilteredVisitors(result.filteredVisitors);
        setSelectedVisitorTypeOption(result.selectedVisitorType);
        setVisitorTypeOptions(result.visitorTypeOptions);
    }, []);

    useInterval(async () => {
        if(!location)
            return;

        const res = await getVisitors(location.Id);
        setVisitors(res);

        filterVisitorsInternal(res, selectedVisitorTypeOption, search, onSiteOnly);
        //setFilteredVisitors(filterVisitors(res, search, onSiteOnly));

        const codeRes = await getCode(location.Id);
        setCode(codeRes);
    }, 60000);

    const printerIframeRef = useRef<HTMLIFrameElement | null>();

    const refresh = useCallback(async (locId: number | undefined, selectedVisitorType: string, searchString: string, onsiteonly: boolean) => {
        try{
            if(!locId)
                return;

            setRefreshing(true);
            // if(timerId.current)
            //     clearInterval(timerId.current);

            const res = await getVisitors(locId);
            setVisitors(res);
            filterVisitorsInternal(res, selectedVisitorType, searchString, onsiteonly);
            //setFilteredVisitors(filterVisitors(res, searchString, onsiteonly));
    
            const codeRes = await getCode(locId);
            setCode(codeRes);
            
            // timerId.current = setInterval(async () => {
            //     const res = await getVisitors(locId);
            //     setVisitors(res);
            //     setFilteredVisitors(filterVisitors(res, searchString, onsiteonly));
    
            //     const codeRes = await getCode(locId);
            //     setCode(codeRes);
            // }, 60000);
    
            setRefreshing(false);
        }
        catch(err: any) {
            if(err instanceof UnauthorizedError)
                //history.replace("/login");
                window.location.href = `${window.location.origin}/login`;
            else
                console.error(err);
        }
    }, [filterVisitorsInternal]);

    const resubscribe = useCallback((locGUID: string | undefined, locId: number | undefined, selectedVisitorType: string, searchString: string, onsiteonly: boolean) => {
        if(!locGUID || !locId) {
            return;
        }
        signalRConnection.current?.unsubscribe(locGUID);
        signalRConnection.current?.subscribe(locGUID, (event: SignalREvent) => {
            if(event.name === locGUID){
                for(const pack of event.data){
                    if(pack.Action === "refresh") {
                        refresh(locId, selectedVisitorType, searchString, onsiteonly);
                    }
                }
            }
        });
    }, [refresh]);

    useEffect(() => {
        (async () => {
            try {
                const locationsRes = await getLocations();
                setLocations(locationsRes);
                let loc: Location | undefined = undefined;
                let locGUID: string = "";
                if(locationsRes && locationsRes?.length > 0){
                    const foundLocation = locationsRes.find(l => l.Id === userState.data.DefaultLocationId)
                    if(userState.data.DefaultLocationId !== 0 && foundLocation) {
                        loc = foundLocation;
                        locGUID = foundLocation.GUID;
                    }
                    else{
                        loc = locationsRes[0];
                        locGUID = locationsRes[0].GUID;
                    }

                    setLocation(loc);
                }

                if(!loc)
                    return;

                refresh(loc.Id, "", "", false);

                const handleEvent = async (event: SignalREvent) => {
                    if(!loc)
                        return;
            
                    if(event.name === loc.GUID){
                        for(const pack of event.data){
                            if(pack.Action === "refresh") {
                                refresh(loc.Id, "", "", false);
                            }
                        }
                    }
                };

                if(!signalRConnection.current){
                    signalRConnection.current = new SignalRConnection();
                    signalRConnection.current.subscribe(locGUID, handleEvent);
                }
            }
            catch(err: any) {
                if(err instanceof UnauthorizedError) 
                    //history.replace("/login");
                window.location.href = `${window.location.origin}/login`;
                else
                    console.error(err);
            }
        })();

        return () => {
            if(signalRConnection.current) {
                signalRConnection.current.stop();
            }

        };
    }, [userState.data.DefaultLocationId, refresh, history]);

    const onSearch = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if(!visitors) // Enter
            return;

        filterVisitorsInternal(visitors, selectedVisitorTypeOption, search, onSiteOnly);
        //setFilteredVisitors(filterVisitors(visitors, search, onSiteOnly));
    }

    const onExpandPhoto = (src: string) => {
        setPhotoViewerSrc(src);
        setPhotoOpen(true);
    };

    const onClosePhoto = () => {
        setPhotoViewerSrc("");
        setPhotoOpen(false);
    };

    const onExpandVisitor = (visitor: Visitor) => {
        setExpandedVisitor(visitor);
        setVisitorOpen(true);
    };

    const onCloseVisitor = () => {
        setVisitorOpen(false);
        setExpandedVisitor(undefined);
    };

    // const onAddVisitor = () => {
    //     setAddVisitorErrorMessage("");
    //     setAddVisitorData({
    //         OrganiserName: "",
    //         VisitorName: "",
    //         VisitorPhone: "",
    //         VisitorEmail: "",
    //         VehicleRegistration: "",
    //         VisitorPhoto: "",
    //         VisitorPhotoFileName: "",
    //         VisitorPhotoContentType: "",
    //         VisitingCompany: "",
    //         VisitorCompany: "",
    //         SpecialAssistance: "",
    //         CheckInCode: "",
    //         Validate: true
    //     });
    //     setAddVisitorModalOpen(true);
    // };

    // const onAddVisitorSave = async () => {
    //     setAddingVisitor(true);
    //     setAddVisitorErrorMessage("");

    //     try {
    //         if(!location)
    //             return;

    //         const codeRes = await getCode(location.Id);
    //         setCode(codeRes);

    //         let visData: AddVisitorData = {...addVisitorData, CheckInCode: codeRes };
    //         if(addVisitorPhotoRef.current?.files && addVisitorPhotoRef.current.files.length > 0) {
    //             const file = addVisitorPhotoRef.current.files[0];
    //             const base64 = await new Promise<string>((resolve, reject) => {
    //                 const reader = new FileReader();
    //                 reader.onload = () => {
    //                     resolve(reader.result as string);
    //                 };
    //                 reader.readAsDataURL(file);
    //             });
    //             visData = {...visData, VisitorPhoto: base64, VisitorPhotoFileName: file.name, VisitorPhotoContentType: file.type};
    //         }

    //         await addVisitor(visData);
    //         //const res = await getVisitors(locationId);
    //         //setVisitors(res);
    //         setSearch("");
    //         //setFilteredVisitors(res);

    //         setAddingVisitor(false);
    //         setAddVisitorModalOpen(false);
    //     }
    //     catch(err) {
    //         if(err instanceof UnauthorizedError) 
    //             history.replace("/login");
    //         else
    //             console.error(err);

    //         setAddingVisitor(false);
    //         setAddVisitorErrorMessage(err.message);
    //     }
    // };

    const onClearVisitors = async () => {
        setMessageBoxState({
            open: true,
            title: "Clear Visitors",
            message: `Are you sure you want to clear todays visitor data for '${location?.Name || ""}'?`,
            okayButtonText: "Yes, Clear",
            cancelButtonText: "No, Cancel",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                setClearing(true);
                try{
                    await deleteLocationVisitors(location?.Id || 0);
                    setClearing(false);
                    setSearch("");
                    setOnSiteOnly(false);
                    resubscribe(location?.GUID, location?.Id, "", "", false);
                    refresh(location?.Id || 0, "", "", false);
                }
                catch(err: any){
                    if(err instanceof UnauthorizedError) 
                        //history.replace("/login");
                        window.location.href = `${window.location.origin}/login`;
                    else
                        console.error(err);

                    setClearing(false);
                    setMessageBoxState({
                        open: true,
                        title: "Error",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                }
            }
        });
    };

    let content: React.ReactNode = null;
    let infoContent: React.ReactNode = null;

    if(visitors && filteredVisitors){
        const signOut = async (visitor: Visitor, locationId: number, currentSearch: string, ev: React.MouseEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            ev.stopPropagation();
            ev.persist(); // React cleans up events in a performant way, this event is needed in a callback thus it must be persisted

            setMessageBoxState({
                open: true,
                title: "Sign Out",
                message: `Are you sure you want to sign out ${visitor.VisitorName}?`,
                okayButtonText: "Yes, Sign Out",
                cancelButtonText: "No, Cancel",
                options: {
                    hasCancel: true,
                    redOkayButton: false
                },
                callback: async (confirmed) => {
                    if(!confirmed)
                        return;
                    
                    (ev.target as any).classList.add("loading");
                    try{
                        await signOutVisitor(visitor.Id);
                        const res = await getVisitors(locationId);
                        setVisitors(res);
                        filterVisitorsInternal(res, selectedVisitorTypeOption, currentSearch, onSiteOnly);
                        //setFilteredVisitors(filterVisitors(res, currentSearch, onSiteOnly));
                    }
                    catch(err: any){
                        if(err instanceof UnauthorizedError) 
                            //history.replace("/login");
                            window.location.href = `${window.location.origin}/login`;
                        else
                            console.error(err);
                    }
                    
                    if(ev && ev.target){
                        (ev.target as any).classList.remove("loading");
                    }
                }
            });
        };

        const notifyVisitor = async (visitor: Visitor, locationId: number, currentSearch: string, ev: React.MouseEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            ev.stopPropagation();
            ev.persist(); // React cleans up events in a performant way, this event is needed in a callback thus it must be persisted

            setMessageBoxState({
                open: true,
                title: "Host Notified",
                message: `Confirm that you have notified ${visitor.Visiting} that their visitor has arrived.`,
                okayButtonText: "Yes",
                cancelButtonText: "No, Cancel",
                options: {
                    hasCancel: true,
                    redOkayButton: false
                },
                callback: async (confirmed) => {
                    if(!confirmed)
                        return;
                    
                    (ev.target as any).classList.add("loading");
                    try{
                        await markVisitorAsNotified(visitor.Id);
                        const res = await getVisitors(locationId);
                        setVisitors(res);
                        filterVisitorsInternal(res, selectedVisitorTypeOption, currentSearch, onSiteOnly);
                        //setFilteredVisitors(filterVisitors(res, currentSearch, onSiteOnly));
                    }
                    catch(err: any){
                        if(err instanceof UnauthorizedError) 
                            //history.replace("/login");
                            window.location.href = `${window.location.origin}/login`;
                        else
                            console.error(err);
                    }
                    
                    if(ev && ev.target){
                        (ev.target as any).classList.remove("loading");
                    }
                }
            });
        };

        const renderedVisitors = filteredVisitors.map(v => (
            <tr key={v.Id.toString()} onClick={_ => onExpandVisitor(v)} style={{ backgroundColor: v.RowColor }}>
                {location?.VisitorTypesEnabled && <td>{v.VisitorTypeName}</td>}
                <td>{prettyLocalTime(v.ArrivalDateTime)}</td>
                <td>
                    {v.DepartureDateTime && prettyLocalTime(v.DepartureDateTime)}
                    {!v.DepartureDateTime && <button className="btn" onClick={ev => signOut(v, location?.Id || 0, search, ev)}>SIGN OUT</button>}    
                </td>
                <td>
                    {v.HostNotified && prettyLocalTime(v.HostNotified)}
                    {!v.HostNotified && !v.DepartureDateTime && <button className="btn" onClick={ev => notifyVisitor(v, location?.Id || 0, search, ev)}>NOTIFIED</button>}    
                    {!v.HostNotified && v.DepartureDateTime && <span>{boolToSymbol(false)}</span>}
                </td>
                {location?.VisitingRequired && <td>{v.Visiting}</td>}
                {location?.VisitingCompanyRequired && <td>{v.VisitingCompany}</td>}
                <td>{v.VisitorName}</td>
                {location?.VisitorCompanyShown && <td>{v.VisitorCompany}</td>}
                {location?.BadgeNumberShown && <td>{v.BadgeNumber}</td>}
                {location?.SpecialAssistanceShown && <td>{boolToSymbol(!!v.SpecialAssistance)}</td>}
                {location?.PhotoRequired && !location.SecureID && <td>
                    <div className={styles.visitorPhotoWrapper}>
                        <span className={styles.visitorPhotoSpacer}></span>
                        {v.VisitorPhotoUrl && <img src={v.VisitorPhotoUrl} alt="Visitor" className={styles.visitorPhoto} />}
                    </div>
                </td>}
            </tr>
        ));

        const selectOptions = locations?.map(l => <option key={l.Id.toString()} label={l.Name} value={l.Id.toString()}>{l.Name}</option>);
        
        const onSelectLocation = (ev: React.ChangeEvent<HTMLSelectElement>) => {
            const locId = Number(ev.target.value);
            const loc = locations?.find(l => l.Id === locId);
            
            if(location)
                signalRConnection.current?.unsubscribe(location.GUID);
            
            if(!loc)
                return;

            signalRConnection.current?.subscribe(loc?.GUID, (event: SignalREvent) => {
                if(!loc)
                        return;
            
                    if(event.name === loc.GUID){
                        for(const pack of event.data){
                            if(pack.Action === "refresh") {
                                refresh(loc.Id, "", "", false);
                            }
                        }
                    }
            });

            setLocation(loc);
            refresh(locId, selectedVisitorTypeOption, search, onSiteOnly);
        };

        const onPrintQRCode = async (type: string) => {
            if(!location)
                return;
    
            const qrString = `${window.location.origin}/meeting/?t=${encodeURIComponent(type)}&l=${encodeURIComponent(location?.GUID)}`;
            onExpandPhoto(await getQRCodePNG(qrString));
        };

        const onSiteOnlyChecked = (checked: boolean) => {
            setOnSiteOnly(checked);
            resubscribe(location?.GUID, location?.Id, selectedVisitorTypeOption, search, checked);
            filterVisitorsInternal(visitors, selectedVisitorTypeOption, search, checked);
            //setFilteredVisitors(filterVisitors(visitors, search, checked));
        };

        const searchChanged = (searchString: string) => {
            setSearch(searchString);
            resubscribe(location?.GUID, location?.Id, selectedVisitorTypeOption, searchString, onSiteOnly);
            filterVisitorsInternal(visitors, selectedVisitorTypeOption, search, onSiteOnly);
            //setFilteredVisitors(filterVisitors(visitors, search, onSiteOnly));
        };

        const selectedVisitorTypeChanged = (selectedVisitorType: string) => {
            setSelectedVisitorTypeOption(selectedVisitorType);
            resubscribe(location?.GUID, location?.Id, selectedVisitorType, search, onSiteOnly);
            filterVisitorsInternal(visitors, selectedVisitorType, search, onSiteOnly);
            //setFilteredVisitors(filterVisitors(visitors, search, onSiteOnly));
        };

        infoContent = (
            <div className={styles.infoContent}>
                <div className={styles.locCodeHeader}>Location Code</div>
                
                <div className={styles.locCode}>{code}</div>
            </div>
        );

        content = (
            <>  
                <div className="flex-row flex-align-items-end">
                    <div className="form-control no-margin-bottom margin-left-15" style={{ maxWidth: "250px" }}>
                        <div className="label">Location</div>
                        <select value={location?.Id} onChange={onSelectLocation}>
                            {selectOptions}
                        </select>
                    </div>

                    {location?.VisitorTypesEnabled && visitorTypeOptions.length > 0 && (
                        <div className="form-control no-margin-bottom margin-left-15" style={{ maxWidth: "250px" }}>
                            <div className="label">Visitor Type</div>
                            <select value={selectedVisitorTypeOption} onChange={ev => selectedVisitorTypeChanged(ev.target.value)}>
                                {[<option key="0" label="All" value="">All</option>].concat(visitorTypeOptions.map((o, i) => <option key={(i+1).toString()} label={o} value={o}>{o}</option>))}
                            </select>
                        </div>
                    )}

                    <div className="form-control no-margin-bottom margin-left-15">
                        <div className="label">Search</div>
                        <input type="text" value={search} onKeyUp={onSearch} onChange={ev => searchChanged(ev.target.value)} />
                    </div>

                    <CheckBox className="flex-align-self-end no-margin-bottom margin-left-15" style={{ maxWidth: "250px" }} label="On Site Only" checked={onSiteOnly} onChecked={c => onSiteOnlyChecked(c)}></CheckBox>

                    <div className="flex-fill"></div>
                    {location?.Demo && (
                        <>
                            <button className="btn margin-left-15" onClick={_ => onPrintQRCode("SignIn")}>SIGN IN QR CODE</button>
                            <button className="btn margin-left-15" onClick={_ => onPrintQRCode("SignOut")}>SIGN OUT QR CODE</button>
                            <button className={`btn margin-left-15${clearing ? " loading" : ""}`} onClick={onClearVisitors}>CLEAR VISITORS</button>
                        </>
                    )}
                    {/* <button className="btn margin-left-15" onClick={onAddVisitor}>ADD VISITOR</button> */}
                    <a className="btn margin-left-15 text-center" href={`${window.location.origin}/meeting/?t=SignIn&l=${location?.GUID}&c=false&did=false`} target="_blank" rel="noopener noreferrer">ADD VISITOR</a>
                    <button className={`btn margin-left-15${refreshing ? " loading" : ""}`} onClick={_ => refresh(location?.Id, selectedVisitorTypeOption, search, onSiteOnly)}>REFRESH</button>
                </div>
                <table className="table table-striped table-font-large table-clickable">
                    <thead>
                    <tr>
                        {location?.VisitorTypesEnabled && <th>Signed In As</th>}
                        <th>Arrived</th>
                        <th>Departed</th>
                        <th>Host Notified</th>
                        {location?.VisitingRequired &&<th>Visiting</th>}
                        {location?.VisitingCompanyRequired &&<th>Visiting Company</th>}
                        <th>Visitor Name</th>
                        {location?.VisitorCompanyShown && <th>Visitor Company</th>}
                        {location?.BadgeNumberShown && <th>Badge Number</th>}
                        {location?.SpecialAssistanceShown && <th>Special Assistance</th>}
                        {location?.PhotoRequired && !location.SecureID && <th>Photo</th>}
                    </tr>
                    </thead>
                    <tbody>
                        {renderedVisitors}
                    </tbody>
                </table>
            </>
        );
    }
    else {
        content = <Loading />;
    }

    const onPrintQRCode = (type: string) => {

        if(!location)
            return;

        const qrString = `${window.location.origin}/meeting/?t=${encodeURIComponent(type)}&l=${encodeURIComponent(location?.GUID)}`;
        printLocationQRCode(type, qrString, printerIframeRef.current, userState.data.ClientDemo);
    };

    const numVisitors = visitors?.length.toString() || "0";
    const numPresentVisitors = visitors?.filter(v => !v.DepartureDateTime).length.toString() || "0";
    return (
        <>
            <div className="flex-row">
                <div>
                    <img className="margin-15" width="300" height="144" src="assets/TAAPHere.png" alt="TAAP Here Visitor Book" title=" Visitor Book" />
                </div>
                {infoContent}
                <div className="flex-fill"></div>
                <Menu hasLogo={false} onCodeClick={onPrintQRCode} />
            </div>
            <div className="container-fullwidth">
                {content}
            </div>
            <div className={styles.visitorInfoBanner}>Date: <span className="text-bold">{readableNow()}</span>&nbsp;&nbsp;&nbsp;Visitors Today: <span className="text-bold">{numVisitors}</span>&nbsp;&nbsp;&nbsp;Visitors Onsite: <span className="text-bold">{numPresentVisitors}</span></div>
            {photoOpen && (
                <div className={styles.photoViewerOverlay} onClick={onClosePhoto}>
                    <div className={styles.photoViewer}>
                        <span className={styles.photoViewerSpacer}></span>
                        <img className={styles.photoViewerPhoto} alt="Visitor" src={photoViewerSrc}></img>
                    </div>
                </div>
            )}
            {visitorOpen && expandedVisitor && (
                <VisitorPanel visitor={expandedVisitor} badgeNumberReadOnly={false} onClose={onCloseVisitor} secureId={location?.SecureID} />
            )}

            <iframe ref={r => printerIframeRef.current = r} src="about:blank" title="TAAP Here Visitor Book" style={{width:"0px", height:"0px", border: "none", outline: "none", margin: "0px", padding: "0px"}}></iframe>
        </>
    );
}

/* VISITOR MODAL

{addVisitorModalOpen && (
    <Modal>
        <div className="flex-row">
            <div className="flex-title">Add Visitor</div>
            <div className="flex-fill"></div>
            <button className="btn" onClick={_ => setAddVisitorModalOpen(false)} style={addingVisitor ? { userSelect: "none", pointerEvents: "none" } : {}}>&#10006;</button>
        </div>

        <div className={styles.addVisitorFormContent}>
            <div className="flex-row">
                <div className="form-control flex-fill">
                    <div className="label">Person Visiting</div>
                    <input type="text" value={addVisitorData.OrganiserName} onChange={ev => setAddVisitorData({...addVisitorData, OrganiserName: ev.target.value})} />
                </div>
                <div style={{ width: "15px" }}></div>
                <div className="form-control flex-fill">
                    <div className="label">Visitor Name</div>
                    <input type="text" value={addVisitorData.VisitorName} onChange={ev => setAddVisitorData({...addVisitorData, VisitorName: ev.target.value})} />
                </div>
            </div>

            <div className="flex-row">
                <div className="form-control flex-fill">
                    <div className="label">Company Visiting</div>
                    <input type="text" value={addVisitorData.VisitingCompany} onChange={ev => setAddVisitorData({...addVisitorData, VisitingCompany: ev.target.value})} />
                </div>
                <div style={{ width: "15px" }}></div>
                <div className="form-control flex-fill">
                    <div className="label">Visitors Company</div>
                    <input type="text" value={addVisitorData.VisitorCompany} onChange={ev => setAddVisitorData({...addVisitorData, VisitorCompany: ev.target.value})} />
                </div>
            </div>
            
            <div className="flex-row">
                <div className="form-control flex-fill">
                    <div className="label">Visitor Phone</div>
                    <input type="text" value={addVisitorData.VisitorPhone} onChange={ev => setAddVisitorData({...addVisitorData, VisitorPhone: ev.target.value})} />
                </div>
                <div style={{ width: "15px" }}></div>
                <div className="form-control flex-fill">
                    <div className="label">Visitor Email</div>
                    <input type="text" value={addVisitorData.VisitorEmail} onChange={ev => setAddVisitorData({...addVisitorData, VisitorEmail: ev.target.value})} />
                </div>
            </div>

            <div className="flex-row">
                <div className="form-control flex-fill">
                    <div className="label">Vehicle Registration</div>
                    <input type="text" value={addVisitorData.VehicleRegistration} onChange={ev => setAddVisitorData({...addVisitorData, VehicleRegistration: ev.target.value})} />
                </div>
                <div style={{ width: "15px" }}></div>
                <div className="form-control flex-fill">
                    <div className="label">Special Assistance</div>
                    <input type="text" value={addVisitorData.SpecialAssistance} onChange={ev => setAddVisitorData({...addVisitorData, SpecialAssistance: ev.target.value})} />
                </div>
            </div>

            <div className="form-control">
                <div className="label">Visitor Photo</div>
                <input type="file" ref={r => addVisitorPhotoRef.current = r} accept="image/*" />
            </div>
        </div>

        <div className="margin-top-15">
            <button className={`btn${addingVisitor ? " loading" : ""}`} onClick={onAddVisitorSave}>SAVE</button>
        </div>

        {addVisitorErrorMessage && <div className={styles.error}>{addVisitorErrorMessage}</div>}
    </Modal>
)}

*/