import React, { createContext, useReducer, useContext, PropsWithChildren } from 'react';

type MessageBoxState = {
    open: boolean,
    title: string,
    message: string,
    okayButtonText?: string
    cancelButtonText?: string
    callback?: (okOrCancel: boolean) => void,
    options: MessageBoxOptionsOptions
};

type MessageBoxOptionsOptions = {
    hasCancel: boolean,
    redOkayButton: boolean
};

export const initialMessageBoxState: MessageBoxState = {
    open: false,
    title: "",
    message: "",
    okayButtonText: "",
    cancelButtonText: "",
    options: {
        hasCancel: true,
        redOkayButton: true
    }
};

const MessageBoxStateContext = createContext<any>({});

const messageBoxStateReducer = (state: any, action: any): MessageBoxState => {
    return action;
};

export const MessageBoxStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(messageBoxStateReducer, initialMessageBoxState);

    return (
        <MessageBoxStateContext.Provider value={[state, dispatch]}>
            {children}
        </MessageBoxStateContext.Provider>
    );
};

export const useMessageBoxState = (): { messageBoxState: MessageBoxState, setMessageBoxState: (updates: MessageBoxState) => void} => {
    const [messageBoxState, dispatch] = useContext(MessageBoxStateContext);

    const setMessageBoxState = (updates: MessageBoxState) => {
        dispatch(updates);
    };

    return { messageBoxState, setMessageBoxState };
};