import React, { useEffect, useState, useRef } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { Loading } from '../../components/Loading/Loading';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { getLocation, Location, saveLocation, deleteLocation, LocationCheck, getLocationChecks, deleteLocationCheck, saveLocationCheck, LocationCheckAddType, addLocationCheck, getTimeZoneInfo, TimeZone, getTypedLocationOptions, addTypedLocation, GetTypedLocationOptionsResponse, getVisitorTypeEmails, getVisitorTypeEmailLocations, GetVisitorTypeEmailLocationsResponse } from '../../api/locations';
import saveMessageStyles from '../../CssModules/SaveMessage.module.css';
import { CheckBox } from '../../components/CheckBox/CheckBox';
import { useMessageBoxState } from '../../contexts/message-box.context';
import { printLocationQRCode } from '../../utils/print.utils';
import { boolToSymbol } from '../../utils/symbol.utils';
import { truncate } from '../../utils/string.utils';
import { Modal } from '../../components/Modal/Modal';
import { Tabs } from '../../components/Tabs/Tabs';

// Type whatever you expect in 'this.props.match.params.*'
type PathParams = {
    locationId: string
}

// Your component own properties
type Props = RouteComponentProps<PathParams>;

export const AdminLocation: React.FC<Props> = ({ match: { params: { locationId } } }) => {

    const history = useHistory();
    const routerLocation = useLocation();
    const { setMessageBoxState } = useMessageBoxState();
    const [saving, setSaving] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    //const [saveMessage, setSaveMessage] = useState<{ message: string, success: boolean }>({ message: "", success: true });
    const [prevLocation, setprevLocation] = useState<Location>();
    const [location, setLocation] = useState<Location>();
    const [locationChecks, setLocationChecks] = useState<LocationCheck[]>([]);
    const [locCheckModalOpen, setLocCheckModalOpen] = useState<boolean>(false);
    const [expandedLocationCheck, setExpandedLocationCheck] = useState<LocationCheck>();
    const [savingLocationCheck, setSavingLocationCheck] = useState<boolean>(false);
    const [saveLocationCheckError, setSaveLocationCheckError] = useState<string>("");

    const [addLocationCheckModalOpen, setAddLocationCheckModalOpen] = useState<boolean>(false);
    const [locationCheckToAdd, setLocationCheckToAdd] = useState<LocationCheckAddType>();
    const [addingLocationCheck, setAddingLocationCheck] = useState<boolean>(false);
    const [addLocationCheckError, setAddLocationCheckError] = useState<string>("");

    const [timeZones, setTimeZones] = useState<TimeZone[]>([]);

    const [typedLocationOptions, setTypedLocationOptions] = useState<GetTypedLocationOptionsResponse[]>([]);
    const [typedLocation, setTypedLocation] = useState<Location>();
    const [addVisitorTypeModalOpen, setAddVisitorTypeModalOpen] = useState<boolean>(false);
    const [addVisitorTypeName, setAddVisitorTypeName] = useState<string>("");
    const [addingVisitorType, setAddingVisitorType] = useState<boolean>(false);
    const [addVisitorTypeError, setAddVisitorTypeError] = useState<string>("");

    const [emailLocations, setEmailLocations] = useState<GetVisitorTypeEmailLocationsResponse[]>([]);
    const [visitorTypeEmails, setVisitorTypeEmails] = useState<{ VisitorTypeName: string, Email: string }[]>();
    const [searchingVisitorEmails, setSearchingVisitorEmails] = useState<boolean>(false);
    const [selectedVisitorEmailLocationId, setSelectedVisitorEmailLocationId] = useState<number>();
    const [visitorEmailSearch, setVisitorEmailSearch] = useState<string>("");

    const qrcodeRef = useRef<any>();
    const printerIframeRef = useRef<HTMLIFrameElement | null>();

    useEffect(() => {
        (async () => {
            try {
                const res = await getLocation(Number(locationId));
                if(!res.TimeZoneId)
                    res.TimeZoneId = "GMT Standard Time";
                setLocation(res);
                setprevLocation({...res});

                const params = new URLSearchParams(routerLocation.search);
                const tl = params.get("tl");
                if(tl && !isNaN(Number(tl))){
                    const typedLocationRes = await getLocation(Number(tl));
                    setTypedLocation(typedLocationRes);

                    const locationChecksRes = await getLocationChecks(Number(tl));
                    setLocationChecks(locationChecksRes);
                }
                else {
                    setTypedLocation(res);
                    const locationChecksRes = await getLocationChecks(Number(locationId));
                    setLocationChecks(locationChecksRes);
                }

                const typedLocationOptionsRes = await getTypedLocationOptions(Number(locationId));
                setTypedLocationOptions(typedLocationOptionsRes);

                const timeZonesRes = await getTimeZoneInfo();
                setTimeZones(timeZonesRes);

                const emailLocations = await getVisitorTypeEmailLocations(Number(locationId));
                setEmailLocations(emailLocations);
            }
            catch(err: any) {}

            return () => {
                if(qrcodeRef.current) {
                    qrcodeRef.current.clear();
                }
            };
        })();
    }, [locationId, routerLocation.search]);



    // const loadQR = (ref: any) => {
    //     qrRef.current = ref;
        
    //     if(location){
    //         QRCode.toCanvas(qrRef.current, "63bcff53-2a69-ea11-a94c-0003ff005614", { errorCorrectionLevel: "H", width: 512 }, (error: Error) => {
    //             if(error)
    //                 console.error(error);
    //         });
    //     }
    // };

    const onSave = async () => {
        //setSaveMessage({ message: "", success: true });
        if(!location || !typedLocation)
            return;

        setMessageBoxState({
            open: true,
            title: "Save Location",
            message: `Are you sure you want to save these location details?`,
            okayButtonText: "YES, SAVE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: false
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                setSaving(true);

                if(!typedLocation.AutoConvertToVisitorTypeExpiryDays || 
                    isNaN(typedLocation.AutoConvertToVisitorTypeExpiryDays) || 
                    typedLocation.AutoConvertToVisitorTypeExpiryDays === 0){
                    typedLocation.AutoConvertToVisitorTypeExpiryDays = undefined;
                }
                else {
                    typedLocation.AutoConvertToVisitorTypeExpiryDays = Math.floor(typedLocation.AutoConvertToVisitorTypeExpiryDays);
                }
        
                try{
                    await saveLocation(location, typedLocation);
                    if(location.VisitorTypesEnabled === false) {
                        typedLocationChanged(location.Id);
                    }
                    setprevLocation({...location});
                    //setSaveMessage({ message: "Saved!", success: true });
                }
                catch (err: any) {
                    //setSaveMessage({ message: err.message, success: false });
                    console.error(err);
                }
        
                setSaving(false);
            }
        });

        
    };

    const onDeleteLocation = () => {
        setMessageBoxState({
            open: true,
            title: "Delete Location",
            message: `Are you sure you want to delete this location? THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, DELETE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                setDeleting(true);
                try{
                    await deleteLocation(location?.Id || 0);
                    history.replace("/admin/locations");
                }
                catch(err: any){
                    setDeleting(false);
                    setMessageBoxState({
                        open: true,
                        title: "Error",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                }
            }
        });
    };

    const onPrintQRCode = (type: string) => {
        if(!location)
            return;

        const qrString = `${window.location.origin}/meeting/?t=${encodeURIComponent(type)}&l=${encodeURIComponent(location?.GUID)}`;
        printLocationQRCode(type, qrString, printerIframeRef.current);
    };

    const onOpenAddLocationCheckModal = () => {
        setLocationCheckToAdd({
            Label: "",
            Text: "",
            Enabled: true,
            EnforceTime: false,
            EnforceTimeMinutes: 0,
            EnforceTimeSeconds: 0
        });

        setAddLocationCheckError("");
        setAddLocationCheckModalOpen(true);
    };

    const onAddLocationCheckClick = async () => {
        if(!locationCheckToAdd || !typedLocation)
            return;

        if(!locationCheckToAdd.EnforceTime) {
            locationCheckToAdd.EnforceTimeMinutes = 0;
            locationCheckToAdd.EnforceTimeSeconds = 0;
        }
        
        if(!Number.isInteger(locationCheckToAdd.EnforceTimeMinutes) || locationCheckToAdd.EnforceTimeMinutes < 0){
            setSaveLocationCheckError("Invalid enforce time minutes");
            return;
        }

        setAddingLocationCheck(true);
        setAddLocationCheckError("");

        try {
            await addLocationCheck(typedLocation.Id, locationCheckToAdd);
            const locationChecksRes = await getLocationChecks(typedLocation.Id);
            setLocationChecks(locationChecksRes);
            setAddingLocationCheck(false);
            setAddLocationCheckModalOpen(false);
        }
        catch(err: any) {
            setAddingLocationCheck(false);
            setAddLocationCheckError(err.message);
        }
    };

    const onLocationCheckClick = (locationCheck: LocationCheck) => {
        setExpandedLocationCheck(locationCheck);
        setSaveLocationCheckError("");
        setLocCheckModalOpen(true);
    };

    const onSaveLocationCheckClick = async () => {
        if(!expandedLocationCheck || !typedLocation)
            return;

        if(!expandedLocationCheck.EnforceTime) {
            expandedLocationCheck.EnforceTimeMinutes = 0;
            expandedLocationCheck.EnforceTimeSeconds = 0;
        }
        
        if(!Number.isInteger(expandedLocationCheck.EnforceTimeMinutes) || expandedLocationCheck.EnforceTimeMinutes < 0){
            setSaveLocationCheckError("Invalid enforce time minutes");
            return;
        }

        setSavingLocationCheck(true);
        setSaveLocationCheckError("");

        try{
            await saveLocationCheck(expandedLocationCheck);
            const locationChecksRes = await getLocationChecks(typedLocation.Id);
            setLocationChecks(locationChecksRes);
            setSavingLocationCheck(false);
            setLocCheckModalOpen(false);
            setExpandedLocationCheck(undefined);
        }
        catch(err: any){
            setSavingLocationCheck(false);
            setSaveLocationCheckError(err.message);
            console.error(err);
        }
    }

    const onDeleteLocationCheckClick = async () => {
        if(!expandedLocationCheck || !typedLocation)
            return;

        setLocCheckModalOpen(false);

        setMessageBoxState({
            open: true,
            title: "Delete Check List Item",
            message: `Are you sure you want to delete the check list item '${expandedLocationCheck.Label}'? THIS CANNOT BE UNDONE.`,
            okayButtonText: "YES, DELETE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                try{
                    await deleteLocationCheck(expandedLocationCheck.Id);
                    const locationChecksRes = await getLocationChecks(typedLocation.Id);
                    setLocationChecks(locationChecksRes);
                }
                catch(err: any){
                    setMessageBoxState({
                        open: true,
                        title: "Error",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                }
            }
        });
    };

    const typedLocationChanged = async (typedLocationId: number) => {
        const typedLoc = await getLocation(typedLocationId);
        setTypedLocation(typedLoc);

        const locationChecksRes = await getLocationChecks(typedLoc.Id);
        setLocationChecks(locationChecksRes);
    };

    const onAddVisitorType = async () => {
        setAddingVisitorType(true);
        try{

            const response = await addTypedLocation(Number(locationId), addVisitorTypeName);
            history.push(`/admin/locations/${locationId}/manage/${response.Id.toString()}`);

            // const typedLocs = await getTypedLocationOptions(Number(locationId));
            // setTypedLocationOptions(typedLocs);

            // setAddVisitorTypeName("");
            // setAddingVisitorType(false);
            // setAddVisitorTypeModalOpen(false);
        }
        catch(err: any) {
            setAddingVisitorType(false);
            setAddVisitorTypeError(err.message);
        }
    };

 
    const onManageVisitorTypesClick = (ev: React.MouseEvent<HTMLButtonElement>, id: number) => {
        ev.stopPropagation();
        history.push(`/admin/locations/${locationId}/manage/${id.toString()}`);
    };

    const onSearchVisitorEmails = async () => {
        setVisitorTypeEmails(undefined);
        setSearchingVisitorEmails(true);
        let res = await getVisitorTypeEmails(location?.Id || 0, selectedVisitorEmailLocationId, visitorEmailSearch);
        const finalList = [];
        res = res.sort((a,b) => a.VisitorTypeName.localeCompare(b.VisitorTypeName));

        const visitorEmailSearchLower = visitorEmailSearch.toLowerCase();
        for(const emailList of res) {
            if(emailList.Emails){
                let split = emailList.Emails.split(",");
                if(visitorEmailSearch)
                    split = split.filter(s => s.toLowerCase().indexOf(visitorEmailSearchLower) !== -1);
                split = split.sort();
                for(const email of split) {
                    finalList.push({ VisitorTypeName: emailList.VisitorTypeName, Email: email });
                }
            }
        }
        setVisitorTypeEmails(finalList);
        setSearchingVisitorEmails(false);
    };

    const renderedLocationChecks = locationChecks.map((lc, index) => (
        <tr key={index.toString()} onClick={_ => onLocationCheckClick(lc)}>
            <td className="table-cell-ellipsis">{lc.Label}</td>
            <td className="table-cell-ellipsis">{truncate(lc.Text, 30)}</td>
            <td>{boolToSymbol(lc.Enabled)}</td>
        </tr>
    ));

    let content = null;
    if(location && typedLocation && prevLocation) {
        content = (
            <>
                <div className="flex-row">
                    <div className="margin-right-15 flex-fill-33">
                        <h1>Location Details</h1>
                        <div className="bordered-container">
                            
                            <div className="form-control">
                                <div className="label">Location Name</div>
                                <input type="text" value={location.Name} onChange={ev => setLocation({...location, Name: ev.target.value})} />
                            </div>

                            <div className="form-control">
                                <div className="label">Visitor Data Retention Period</div>
                                <select value={location.DataRetention} onChange={ev => setLocation({...location, DataRetention: Number(ev.target.value)})} >
                                    {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30].map(n => (
                                        <option key={n.toString()} label={`${n.toString()} days`} value={n.toString()}>{`${n.toString()} days`}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-control">
                                <div className="label">Time Zone</div>
                                <select value={location.TimeZoneId} onChange={ev => setLocation({...location, TimeZoneId: ev.target.value})} >
                                    {timeZones.map((n, index) => (
                                        <option key={index.toString()} label={`${n.DisplayName} days`} value={n.Id}>{`${n.DisplayName} days`}</option>
                                    ))}
                                </select>
                            </div>

                            <CheckBox label="Demo Location" checked={location.Demo} onChecked={c => setLocation({...location, Demo: c})} />
                        </div>
                        <div className="bordered-container margin-top-15">
                            <h2 className="margin-top-0">Print QR Code</h2>

                            <div>
                                <button className="btn margin-bottom-15" onClick={_ => onPrintQRCode("SignIn")}>SIGN IN QR CODE</button>
                            </div>
                            <div>
                                <button className="btn margin-bottom-15" onClick={_ => onPrintQRCode("SignOut")}>SIGN OUT QR CODE</button>
                            </div>
                        </div>
                        <div className="bordered-container margin-top-15">
                            <h2 className="margin-top-0">Common Location Code</h2>
                            
                            <p>Make this location a common/shared location with other organisations using TAAP Visitor Book by sharing this code with them.</p>
                            <h4 className="text-bold">{location.GUID}</h4>
                            
                            <div className="form-control">
                                <div className="label">Enter your shared/serviced office location code here</div>
                                <input type="text" value={location.CommonLocationGUID || ""} onChange={ev => setLocation({...location, CommonLocationGUID: ev.target.value})} />
                            </div>
                        </div>

                        <div className="bordered-container margin-bottom-15 margin-top-15">
                            <h2 className="margin-top-0">Visitor Types</h2>
                            <p>Select Enabled to add more Visitor Types e.g Contractor, Employee.</p>
                            <p>If not selected then all visitors will sign in under the default settings.</p>
                            <CheckBox label="Enabled" checked={location.VisitorTypesEnabled} onChecked={c => {
                                setLocation({...location, VisitorTypesEnabled: c});
                            }} />
                            <p className="no-margin-bottom">Click SAVE</p>
                        </div>

                        <div className="flex-row margin-top-15">
                            <button className={`btn${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                            {/* <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessage.success ? saveMessageStyles.success : saveMessageStyles.error}`}>{saveMessage.message}</span> */}
                        </div>
                    </div>
                    
                    <div className="flex-fill-33 margin-right-15">
                        {!prevLocation.VisitorTypesEnabled && <h1>Sign In Form Details</h1>}
                        {prevLocation.VisitorTypesEnabled && <h1>Sign In Form Details - <span style={{ color: "red" }}>Visitor Type: {typedLocation.VisitorTypeName}</span></h1>}
                    
                        <div className="bordered-container margin-top-15">
                            <h2 className="margin-top-0">Sign in Email Notification</h2>
                            <p>Add an additional email address for sign in notifications to be sent to</p>
                            <div className="form-control">
                                <input type="text" value={typedLocation.SignInNotificationEmail || ""} onChange={ev => setTypedLocation({...typedLocation, SignInNotificationEmail: ev.target.value})} />
                            </div>

                            <CheckBox label="Secure ID" checked={typedLocation.SecureID} onChecked={c => setTypedLocation({...typedLocation, SecureID: c})} />
                            <p>When enabled this will hide all sensitive data from the visitor book screen apart from the Visitors Name. Only admin will have access via the search function.</p>
                        </div>
                        
                        <div className="bordered-container margin-top-15">
                            <h2 className="margin-top-0">Visitor Sign In Instructions</h2>
                            <p>Enter guidance instructions for your Visitor to follow should they not be able to sign in for any reason.</p>
                            <div className="form-control">
                                <textarea rows={4} value={typedLocation.VisitorSignInInstructions || ""} onChange={ev => setTypedLocation({...typedLocation, VisitorSignInInstructions: ev.target.value})} />
                            </div>
                        </div>

                        <div className="bordered-container margin-top-15">
                            <h2 className="margin-top-0">Display Mandatory Fields</h2>
                            <p>Selecting these fields will make it mandatory for the visitor to complete on sign in. Completed details will appear against the visitor when clicking into their name in the visitor book table.</p>
                            <CheckBox label="Person Visiting" checked={typedLocation.VisitingRequired} onChecked={c => setTypedLocation({...typedLocation, VisitingRequired: c})} />

                            <CheckBox label="Company Visiting" checked={typedLocation.VisitingCompanyRequired} onChecked={c => setTypedLocation({...typedLocation, VisitingCompanyRequired: c})} />

                            <CheckBox label="Email" checked={typedLocation.EmailRequired} onChecked={c => setTypedLocation({...typedLocation, EmailRequired: c})} />

                            <CheckBox label="Phone" checked={typedLocation.PhoneNumberRequired} onChecked={c => setTypedLocation({...typedLocation, PhoneNumberRequired: c})} />

                            <CheckBox label="Photo" checked={typedLocation.PhotoRequired} onChecked={c => setTypedLocation({...typedLocation, PhotoRequired: c})} />

                            <CheckBox label="Miles Travelled" checked={typedLocation.MilesTravelledRequired} onChecked={c => setTypedLocation({...typedLocation, MilesTravelledRequired: c})} />

                            <CheckBox label="Mode of Transport" checked={typedLocation.ModeOfTransportRequired} onChecked={c => setTypedLocation({...typedLocation, ModeOfTransportRequired: c})} />

                            <CheckBox label="Driver of the Vehicle / FuelType" checked={typedLocation.DriverOfTheVehicleAndFuelTypeRequired} onChecked={c => setTypedLocation({...typedLocation, DriverOfTheVehicleAndFuelTypeRequired: c})} />

                            <h2 className="margin-top-0">Display Optional Fields</h2>
                            <p>Selecting these fields will allow the visitor the option to complete when signing in but will not be mandatory. if the visitor completes these details they will appear against the visitor when clicking into their name in the visitor book table.</p>
                            <CheckBox label="Visitors Company" checked={typedLocation.VisitorCompanyShown} onChecked={c => setTypedLocation({...typedLocation, VisitorCompanyShown: c})} />
                            <CheckBox label="Vehicle Registration" checked={typedLocation.VehicleRegistrationShown} onChecked={c => setTypedLocation({...typedLocation, VehicleRegistrationShown: c})} />
                            <CheckBox label="Badge Number" checked={typedLocation.BadgeNumberShown} onChecked={c => setTypedLocation({...typedLocation, BadgeNumberShown: c})} />
                            <CheckBox label="Postcode" checked={typedLocation.PostcodeShown} onChecked={c => setTypedLocation({...typedLocation, PostcodeShown: c})} />
                            <CheckBox label="Special Assistance" checked={typedLocation.SpecialAssistanceShown} onChecked={c => setTypedLocation({...typedLocation, SpecialAssistanceShown: c})} />

                            <h2 className="margin-top-0">Optional Features</h2>
                            <p>Here you can select to enable or disable features relevant to the visitors experience.</p>
                            <CheckBox label="Show Visitor Badge" checked={typedLocation.VisitorBadgeEnabled} onChecked={c => setTypedLocation({...typedLocation, VisitorBadgeEnabled: c})} />
                        </div>
                        

                        <div className="bordered-container bordered-container-red margin-top-15">
                            <h2 className="margin-top-0">Check List</h2>
                            <p>Add a checklist of information that a visitor must agree to when they sign in to this location.</p>
                            
                            <button className="btn" onClick={onOpenAddLocationCheckModal}>ADD CHECK LIST ITEM</button>
                            <table className="table table-align-text-left table-clickable-shaded">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Content</th>
                                        <th>Enabled</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderedLocationChecks}
                                </tbody>
                            </table>
                        </div>

                        <div className="flex-row margin-top-15">
                            <button className={`btn${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                            {/* <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessage.success ? saveMessageStyles.success : saveMessageStyles.error}`}>{saveMessage.message}</span> */}
                            <div className="flex-fill"></div>
                            <button className={`btn red-button${deleting ? " loading" : ""}`} onClick={onDeleteLocation}>DELETE LOCATION</button>
                        </div>
                    </div>

                    <div className="flex-fill-33">
                        {prevLocation.VisitorTypesEnabled && <>
                            <h1>Visitor Types</h1>
                            <div className="bordered-container">
                            
                                    <button className="btn" onClick={_ => {setAddVisitorTypeName(""); setAddVisitorTypeError(""); setAddVisitorTypeModalOpen(true);}}>ADD</button>
                                    <p className="margin-bottom-15">Select ADD to add Visitor Types, eg Contractor, Employee.</p>

                                    <table className="table table-clickable table-highlight-gray">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typedLocationOptions.map(n => (
                                                <tr key={n.Id.toString()} onClick={_ => typedLocationChanged(n.Id)} style={{ backgroundColor: n.Id === typedLocation.Id ? "#b5ead7" : "#ffffff" }}>
                                                    <td>{n.VisitorTypeName}</td>
                                                    <td>
                                                        <button className="btn" style={{ visibility: n.Id === typedLocation.Id ? "visible": "hidden" }} onClick={ev => onManageVisitorTypesClick(ev, n.Id)}>MANAGE</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                            
                            </div>
                        </>}
                    </div>
                </div>
            
                <iframe ref={r => printerIframeRef.current = r} src="about:blank" title="TAAP Here Visitor Book" style={{width:"0px", height:"0px", border: "none", outline: "none", margin: "0px", padding: "0px"}}></iframe>
            </>
        );
    };

    return (
        <>
            <Menu isAdmin={true} />
            <div className="container">
                <div className="flex-row margin-top-15 margin-bottom-15 ">
                    <button className="btn margin-right-15" onClick={_ => history.goBack()}>BACK</button>
                    <button className={`btn${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                    {/* <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessage.success ? saveMessageStyles.success : saveMessageStyles.error}`}>{saveMessage.message}</span> */}
                </div>
                
                {(!location || !typedLocation || !prevLocation) && <Loading />}
                {(emailLocations.length === 0 || !location?.VisitorTypesEnabled) && content}
                {(emailLocations.length > 0 && location?.VisitorTypesEnabled) && (
                    <Tabs tabs={[
                        {
                            label: "Settings",
                            component: content
                        },
                        {
                            label: "Email Addresses",
                            component: <>
                                <h3>Search email addresses added against different visitor types to see who has not signed in yet.</h3>
                                <div className="flex-row margin-top-15">
                                <div className="form-control no-margin-bottom">
                                    <div className="label">Visitor Type</div>
                                        <select value={selectedVisitorEmailLocationId} onChange={ev => setSelectedVisitorEmailLocationId(Number(ev.target.value) || undefined)}>
                                            <option key="0" label="All" value={undefined}>All</option>
                                            {emailLocations.map(n => (
                                                <option key={n.Id.toString()} label={n.Name} value={n.Id.toString()}>{n.Name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-control margin-left-15 no-margin-bottom">
                                        <div className="label">Search</div>
                                        <input type="text" value={visitorEmailSearch} onChange={ev => setVisitorEmailSearch(ev.target.value)} />
                                    </div>
                                    <button className={`btn margin-left-15 flex-align-self-end${searchingVisitorEmails ? " loading": ""}`} onClick={onSearchVisitorEmails}>SEARCH</button>
                                </div>
                                {searchingVisitorEmails && <Loading margin="15px" />}
                                {!searchingVisitorEmails && visitorTypeEmails && (
                                    <table className="table table-shaded-header" style={{ width: "100%", maxWidth: "600px" }}>
                                        <thead>
                                        <tr>
                                            <th>Visitor Type</th>
                                            <th>Email</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {visitorTypeEmails.map((e, i) => (
                                                <tr key={i.toString()}>
                                                    <td>{e.VisitorTypeName}</td>
                                                    <td>{e.Email}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </>
                        }
                    ]} />
                )}
            </div>

            {locCheckModalOpen && expandedLocationCheck && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Check List Item</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setLocCheckModalOpen(false)}>&#10006;</button>
                    </div>
                    <div className="form-control">
                        <div className="label">Title</div>
                        <input type="text" value={expandedLocationCheck.Label} onChange={ev => setExpandedLocationCheck({...expandedLocationCheck, Label: ev.target.value})} />
                    </div>

                    <div className="flex-row">
                        <div className="form-control no-margin-bottom" style={{ maxWidth: "150px" }}>
                            <CheckBox style={{ marginBottom: "0px" }} label="Timer" checked={expandedLocationCheck.EnforceTime} onChecked={c => setExpandedLocationCheck({...expandedLocationCheck, EnforceTime: c})} />
                        </div>
                        <p className="flex-fill flex-align-self-end margin-left-15">Select to enforce time for reading before being able to continue.</p>
                    </div>

                    {expandedLocationCheck.EnforceTime && (
                        <div className="flex-row margin-bottom-15">
                            <div className="form-control no-margin-bottom" style={{ width: "60px", maxWidth: "60px" }}>
                                <input style={{ marginBottom: "0px" }} type="number" min="0" max="59" value={expandedLocationCheck.EnforceTimeMinutes} onChange={ev => setExpandedLocationCheck({...expandedLocationCheck, EnforceTimeMinutes: Number(ev.target.value)})}></input>
                            </div>
                            <p className="flex-align-self-end">&nbsp;Minutes</p>
                            {/* <div className="form-control no-margin-bottom" style={{ width: "60px", maxWidth: "60px" }}>
                                <input style={{ marginBottom: "0px" }} type="number" min="0" max="59" value={expandedLocationCheck.EnforceTimeSeconds} onChange={ev => setExpandedLocationCheck({...expandedLocationCheck, EnforceTimeSeconds: Number(ev.target.value)})}></input>
                            </div>
                            <p className="flex-align-self-end">&nbsp;Seconds</p> */}
                        </div>
                    )}

                    <div className="form-control no-margin-bottom">
                        <div className="label">Content</div>
                        <textarea rows={7} value={expandedLocationCheck.Text} onChange={ev => setExpandedLocationCheck({...expandedLocationCheck, Text: ev.target.value})} />
                    </div>
                    <p>You can create hyper-links in the content using the convention [Friendly Label](Web Link). Spaces or new lines between [] and () are not allowed.<br/>e.g. [Click Here!](https://ontaap.com/visitorbook)</p>

                    <CheckBox label="Enabled" checked={expandedLocationCheck.Enabled} onChecked={c => setExpandedLocationCheck({...expandedLocationCheck, Enabled: c})} />

                    <div className="flex-row margin-top-15">
                        <button className={`btn${savingLocationCheck ? " loading" : ""}`} onClick={onSaveLocationCheckClick}>SAVE</button>
                        <span className={`flex-align-self-center flex-fill ${saveMessageStyles.saveMessage} ${saveMessageStyles.error}`}>{saveLocationCheckError}</span>
                        <button className="btn red-button" onClick={onDeleteLocationCheckClick}>DELETE</button>
                    </div>
                </Modal>
            )}

            {addLocationCheckModalOpen && locationCheckToAdd && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add Check List Item</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setAddLocationCheckModalOpen(false)}>&#10006;</button>
                    </div>
                    <div className="form-control">
                        <div className="label">Title</div>
                        <input type="text" value={locationCheckToAdd.Label} onChange={ev => setLocationCheckToAdd({...locationCheckToAdd, Label: ev.target.value})} />
                    </div>

                    <div className="flex-row">
                        <div className="form-control no-margin-bottom" style={{ maxWidth: "150px" }}>
                            <CheckBox style={{ marginBottom: "0px" }} label="Timer" checked={locationCheckToAdd.EnforceTime} onChecked={c => setLocationCheckToAdd({...locationCheckToAdd, EnforceTime: c})} />
                        </div>
                        <p className="flex-fill flex-align-self-end margin-left-15">Select to enforce time for reading before being able to continue.</p>
                    </div>

                    {locationCheckToAdd.EnforceTime && (
                        <div className="flex-row margin-bottom-15">
                            <div className="form-control no-margin-bottom" style={{ width: "60px", maxWidth: "60px" }}>
                                <input style={{ marginBottom: "0px" }} type="number" min="0" max="59" value={locationCheckToAdd.EnforceTimeMinutes} onChange={ev => setLocationCheckToAdd({...locationCheckToAdd, EnforceTimeMinutes: Number(ev.target.value)})}></input>
                            </div>
                            <p className="flex-align-self-end">&nbsp;Minutes</p>
                            {/* <div className="form-control no-margin-bottom" style={{ width: "60px", maxWidth: "60px" }}>
                                <input style={{ marginBottom: "0px" }} type="number" min="0" max="59" value={locationCheckToAdd.EnforceTimeSeconds} onChange={ev => setLocationCheckToAdd({...locationCheckToAdd, EnforceTimeSeconds: Number(ev.target.value)})}></input>
                            </div>
                            <p className="flex-align-self-end">&nbsp;Seconds</p> */}
                        </div>
                    )}

                    <div className="form-control no-margin-bottom">
                        <div className="label">Content</div>
                        <textarea rows={7} value={locationCheckToAdd.Text} onChange={ev => setLocationCheckToAdd({...locationCheckToAdd, Text: ev.target.value})} />
                    </div>
                    <p>You can create hyper-links in the content using the convention [Friendly Label](Web Link). Spaces or new lines between [] and () are not allowed.<br/>e.g. [Click Here!](https://ontaap.com/visitorbook)</p>

                    <CheckBox label="Enabled" checked={locationCheckToAdd.Enabled} onChecked={c => setLocationCheckToAdd({...locationCheckToAdd, Enabled: c})} />

                    <div className="flex-row margin-top-15">
                        <button className={`btn${addingLocationCheck ? " loading" : ""}`} onClick={onAddLocationCheckClick}>ADD</button>
                        <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessageStyles.error}`}>{addLocationCheckError}</span>
                    </div>
                </Modal>
            )}

            {addVisitorTypeModalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add Visitor Type</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setAddVisitorTypeModalOpen(false)}>&#10006;</button>
                    </div>
                    <div className="form-control">
                        <div className="label">Visitor Type Name</div>
                        <input type="text" value={addVisitorTypeName} onChange={ev => setAddVisitorTypeName(ev.target.value)} />
                    </div>

                    <div className="flex-row margin-top-15">
                        <button className={`btn${addingVisitorType ? " loading" : ""}`} onClick={onAddVisitorType}>ADD</button>
                        <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessageStyles.error}`}>{addVisitorTypeError}</span>
                    </div>
                </Modal>
            )}
        </>
    );
};