import React, { useEffect, useState } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { getLocations, Location, addLocation as addLocationRemote } from '../../api/locations';
import { Loading } from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import styles from './AdminLocations.module.css';
import { Modal } from '../../components/Modal/Modal';
import { boolToSymbol } from '../../utils/symbol.utils';

export const AdminLocations: React.FC = () => {

    const history = useHistory();
    const [locations, setLocations] = useState<Location[]>([]);
    const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
    const [addLocation, setAddLocation] = useState<{ Name: string }>({ Name: "" });
    const [addingLocation, setAddingLocation] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [addErrorMessage, setAddErrorMessage] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const goTo = (route: string) => {
        history.push(route);
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await getLocations();
                setLocations(res);
                setFilteredLocations(res);
            }
            catch(err: any) {}
        })();
    }, []);

    let renderedLocations = null;
    if(locations){
        renderedLocations = filteredLocations.map(l => (
            <tr key={l.Id.toString()}>
                <td>{l.Name}</td>
                <td>{boolToSymbol(l.Demo)}</td>
                <td>
                    <button className={`btn ${styles.actionButton}`} onClick={_ => goTo(`/admin/locations/${l.Id}`)}>VIEW / EDIT</button>
                </td>
            </tr>
        ));
    }

    const onAddLocation = () => {
        setAddLocation({
            Name: ""
        });
        setModalOpen(true);
    };

    const onSaveAddLocation = async () => {
        setAddingLocation(true);

        try{
            await addLocationRemote(addLocation.Name);
            setAddingLocation(false);
            setModalOpen(false);

            const res = await getLocations();
            setLocations(res);
            setSearch("");
            setFilteredLocations(res);
        }
        catch(err: any) {
            setAddingLocation(false);
            setAddErrorMessage(err.message);
        }
    };

    const onSearch = () => {
        setFilteredLocations(locations?.filter(l => l.Name.toLowerCase().includes(search.toLowerCase())) || locations);
    };

    return (
        <>
            <Menu isAdmin={true} />
            <div className="container-skinny">
                {!locations && <Loading />}
                {locations && (
                    <>
                        <div className="form-control" style={{ maxWidth: "300px" }}>
                            <div className="label">Search</div>
                            <input type="text" value={search} onKeyUp={onSearch} onChange={ev => setSearch(ev.target.value)} />
                        </div>
                        <div className="flex-row">
                            <div className="flex-large-title">Locations</div>
                            <div className="flex-fill"></div>
                            <button className="btn" onClick={onAddLocation}>ADD LOCATION</button>
                        </div>
                        <table className="table table-shaded-header table-font-large">
                            <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Demo Location</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {renderedLocations}
                            </tbody>
                        </table>
                    </>
                )}
            </div>

            {modalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add Location</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setModalOpen(false)}>&#10006;</button>
                    </div>
                    <div className="form-control">
                        <div className="label">Location Name</div>
                        <input type="text" value={addLocation.Name} onChange={ev => setAddLocation({...addLocation, Name: ev.target.value})} />
                    </div>

                    <div>
                        <button className={`btn${addingLocation ? " loading" : ""}`} onClick={onSaveAddLocation}>SAVE</button>
                    </div>

                    <div className="error-message">{addErrorMessage}</div>
                </Modal>
            )}
        </>
    );
};