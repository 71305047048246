import React, { useEffect, useState, useRef } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { Loading } from '../../components/Loading/Loading';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getUser, User, saveUser, changePassword, deleteUser } from '../../api/users';
import saveMessageStyles from '../../CssModules/SaveMessage.module.css';
import { getLocations, Location } from '../../api/locations';
import { Modal } from '../../components/Modal/Modal';
import { UserForm } from '../../components/UserForm/UserForm';
import { useMessageBoxState } from '../../contexts/message-box.context';

// Type whatever you expect in 'this.props.match.params.*'
type PathParams = {
    userId: string
}

// Your component own properties
type Props = RouteComponentProps<PathParams>;

export const AdminUser: React.FC<Props> = ({ match: { params: { userId } } }) => {

    const { setMessageBoxState } = useMessageBoxState();
    const [deleting, setDeleting] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [changingPassword, setChangingPassword] = useState<boolean>(false);
    const [changePasswordPack, setChangePasswordPack] = useState<{ password: string, confirmPassword: string, error: string }>({ password: "", confirmPassword: "", error: "" });
    const [saving, setSaving] = useState<boolean>(false);
    const [saveMessage, setSaveMessage] = useState<{ message: string, success: boolean }>({ message: "", success: true });
    const [user, setUser] = useState<User>();
    const [locations, setLocations] = useState<Location[]>();
    const qrcodeRef = useRef<any>();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const res = await getUser(Number(userId));
                setUser(res);

                const locs = await getLocations();
                setLocations(locs);
            }
            catch(err: any) {}

            return () => {
                if(qrcodeRef.current) {
                    qrcodeRef.current.clear();
                }
            };
        })();
    }, [userId]);

    const onSave = async () => {
        setSaveMessage({ message: "", success: true });
        if(!user)
            return;

        setSaving(true);

        try{
            await saveUser(user);
            setSaveMessage({ message: "Saved!", success: true });
        }
        catch (err: any) {
            setSaveMessage({ message: err.message, success: false });
            console.error(err);
        }

        setSaving(false);
    };

    const onChangePassword = async () => {
        if(!user)
            return;

        const cpp = changePasswordPack;
        if(cpp.password.length < 8) {
            setChangePasswordPack({...changePasswordPack, error: "Password must be at least 8 characters in length"});
            return;
        }

        if(cpp.confirmPassword !== cpp.password) {
            setChangePasswordPack({...changePasswordPack, error: "Passwords must match"});
            return;
        }

        setChangingPassword(true);

        await changePassword(user.Id, cpp.password);

        setChangingPassword(false);
        setModalOpen(false);
    };

    const onDeleteUser = () => {
        setMessageBoxState({
            open: true,
            title: "DELETE USER",
            message: `Are you sure you want to delete '${user?.DisplayName || ""}'?`,
            okayButtonText: "YES, DELETE",
            cancelButtonText: "NO, CANCEL",
            options: {
                hasCancel: true,
                redOkayButton: true
            },
            callback: async (confirmed) => {
                if(!confirmed)
                    return;

                 setDeleting(true);
                try{
                    await deleteUser(user?.Id || 0);
                    history.replace("/admin/users");
                }
                catch(err: any){
                    setDeleting(false);
                    setMessageBoxState({
                        open: true,
                        title: "Error",
                        message: err.message,
                        options: {
                            hasCancel: false,
                            redOkayButton: false
                        }
                    });
                }
             }
        });
    };

    const selectOptions = locations?.map(l => <option key={l.Id.toString()} label={l.Name} value={l.Id.toString()}>{l.Name}</option>);
    selectOptions?.unshift(<option key="0" label="None" value="0">None</option>);
    return (
        <>
            <Menu isAdmin={true} />
            <div className="container-skinny">
                <button className="btn margin-bottom-15" onClick={_ => history.goBack()}>BACK</button>
                {!user && <Loading />}
                {user && (
                    <>
                        <h1>User</h1>
                        <UserForm user={user} onChange={user => setUser(user as User)} locations={locations || []} active={true} />

                        <div className="flex-row">
                            <button className={`btn${saving ? " loading" : ""}`} onClick={onSave}>SAVE</button>
                            <span className={`flex-align-self-center ${saveMessageStyles.saveMessage} ${saveMessage.success ? saveMessageStyles.success : saveMessageStyles.error}`}>{saveMessage.message}</span>
                        </div>

                        <div>
                            <button className="btn margin-top-15" onClick={_ => setModalOpen(true)}>CHANGE PASSWORD</button>
                        </div>
                        <div>
                            <button className={`btn red-button margin-top-15${deleting ? " loading" : ""}`} onClick={onDeleteUser}>DELETE USER</button>
                        </div>
                    </>
                )}
            </div>

            {modalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Change Password</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setModalOpen(false)}>&#10006;</button>
                    </div>
                    <div className="form-control">
                        <div className="label">Password</div>
                        <input type="password" value={changePasswordPack.password} onChange={ev => setChangePasswordPack({...changePasswordPack, password: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Confirm Password</div>
                        <input type="password" value={changePasswordPack.confirmPassword} onChange={ev => setChangePasswordPack({...changePasswordPack, confirmPassword: ev.target.value})} />
                    </div>

                    <div>
                        <button className={`btn${changingPassword ? " loading" : ""}`} onClick={onChangePassword}>SAVE</button>
                    </div>

                    <div className="error-message">{changePasswordPack.error}</div>
                </Modal>
            )}
        </>
    );
};