import React from 'react';
import { Menu } from '../../components/Menu/Menu';

export const Addin: React.FC = () => {
    return (
        <>
            <Menu isAdmin={true} />
            <div className="container-skinny">
                <a className="btn margin-bottom-15" href="/addin/manifest.xml" download="manifest.xml" target="_blank">DOWNLOAD MANIFEST</a>
            </div>
        </>
    );
};