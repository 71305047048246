import React from 'react';
import { User, NewUser } from '../../api/users';
import { Location } from '../../api/locations';
import { CheckBox } from '../CheckBox/CheckBox';

type Props = {
    user: User | NewUser,
    locations: Location[],
    password?: boolean,
    active?: boolean,
    onChange: (user: User | NewUser) => void
};

export const UserForm: React.FC<Props> = ({ user, locations, password, active, onChange }) => {

    const selectOptions = locations.map(l => <option key={l.Id.toString()} label={l.Name} value={l.Id.toString()}>{l.Name}</option>);
    selectOptions?.unshift(<option key="0" label="None" value="0">None</option>);

    return (
        <>
            <div className="form-control">
                <div className="label">Display Name</div>
                <input type="text" value={user.DisplayName} onChange={ev => onChange({...user, DisplayName: ev.target.value})} />
            </div>

            <div className="form-control">
                <div className="label">Email</div>
                <input type="text" value={user.Email} onChange={ev => onChange({...user, Email: ev.target.value})} />
            </div>

            {password && (
                <div className="form-control">
                    <div className="label">Password</div>
                    <input type="password" value={(user as NewUser).Password} onChange={ev => onChange({...user, Password: ev.target.value})} />
                </div>
            )}

            <div className="form-control">
                <div className="label">Default Location</div>
                <select value={user.DefaultLocationId} onChange={ev => onChange({...user, DefaultLocationId: Number(ev.target.value)})}>
                    {selectOptions}
                </select>
            </div>

            <div className="form-control">
                <div className="label">Role</div>
                <select value={user.Role} onChange={ev => onChange({...user, Role: ev.target.value})}>
                    <option label="User" value="User">User</option>
                    <option label="Admin" value="Admin">Admin</option>
                </select>
            </div>

            {active && <CheckBox label="Active" checked={(user as User).Active} onChecked={c => onChange({...user, Active: c})} />}
        </>
    );
};