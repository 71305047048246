import React, { useState } from 'react';
import { resetPassword } from '../../api/users';
import { RouteComponentProps, useHistory } from 'react-router-dom';

type PathParams = {
    resetToken: string
}

// Your component own properties
type Props = RouteComponentProps<PathParams>;

export const PasswordReset: React.FC<Props> = ({ match: { params: { resetToken } } }) => {

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [changingPassword, setChangingPassword] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const history = useHistory();

    const onChangePassword = async () => {
        setChangingPassword(true);

        try{
            await resetPassword(password, confirmPassword, resetToken);
            setReset(true);
        }
        catch (err: any) {
            setChangingPassword(false);
            setError(err.message);
        }
    };

    const goTo = (route: string) => {
        history.replace(route);
    };

    return (
        <div className="container-tiny-center">
            <h1>Reset Password</h1>

            {!reset && (
                <>
                    <div className="form-control">
                        <div className="label">Password</div>
                        <input type="password" value={password} onChange={ev => setPassword(ev.target.value)} />
                    </div>

                    <div className="form-control">
                        <div className="label">Confirm Password</div>
                        <input type="password" value={confirmPassword} onChange={ev => setConfirmPassword(ev.target.value)} />
                    </div>

                    <button className={`btn${changingPassword ? " loading" : ""}`} onClick={onChangePassword}>SAVE</button>
       
                    <div className="error-message">{error}</div>
                </>
            )}

            {reset && (
                <>
                    <div className="success-message">Password reset successfully! Click below to login.</div>
                    <button className="btn margin-top-15" onClick={_ => goTo("/login")}>GO TO LOGIN</button>
                </>
            )}
        </div>
    );
};