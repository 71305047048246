import React, { useState } from 'react';
import styles from './Tabs.module.css';

type Props = {
    tabs: { label: string, component: React.ReactNode }[],
    onTabChange?: (index: number) => void
};

export const Tabs: React.FC<Props> = ({ tabs, onTabChange }) => {

    const [index, setIndex] = useState<number>(0);

    const onClick = (i: number) => {
        setIndex(i);

        if(i !== index && onTabChange){
            onTabChange(i);
        }
    };

    if(!tabs || tabs.length === 0)
        return null;

    const percentage = (100 / tabs.length).toString() + "%";

    return (
        <div>
            <div className="flex-row">
                {tabs.map((t, i) => (
                    <div key={i.toString()} style={{ flex: `1 1 ${percentage}` }} className={`${styles.header} ${i === index ? " " + styles.active : ""}`} onClick={_ => onClick(i)}>
                        {t.label}
                    </div>
                ))}
            </div>
            
            <div>
                {tabs[index].component}
            </div>
        </div>
    );
};
