import React from 'react';
import styles from './SummaryElement.module.css';

type Props = {
    label: string,
    value: string
};

export const SummaryElement: React.FC<Props> = ({ label, value }) => {
    return (
        <div className={styles.container}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
        </div>
    );
};