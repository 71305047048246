import React, { useState, useCallback, useEffect } from 'react';
import styles from './Menu.module.css';
import { useHistory } from 'react-router-dom';
import { logout } from '../../api/auth';
import { useUserState } from '../../contexts/user.context';
import { setUserAdminWelcome } from '../../api/users';

type Props = {
    hasLogo?: boolean,
    isAdmin?: boolean,
    onCodeClick?: (type: string) => void;
};

export const Menu: React.FC<Props> = ({ hasLogo = true, onCodeClick, isAdmin }) => {

    const history = useHistory();
    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const [helpMenuOpen, setHelpMenuOpen] = useState<boolean>(false);
    const [codesMenuOpen, setCodesMenuOpen] = useState<boolean>(false);
    const { userState, setUserState } = useUserState();

    const onAnywhereElseClicked = useCallback(() => {
        setHelpMenuOpen(false);
        setCodesMenuOpen(false);
        document.removeEventListener("click", onAnywhereElseClicked);
    }, []);

    useEffect(() => {
        return () => {
            document.removeEventListener("click", onAnywhereElseClicked);
        };
    }, [onAnywhereElseClicked]);

    const goTo = (route: string) => {
        history.push(route);
    };

    const onLogout = async () => {
        setLoggingOut(true);

        try{
            await logout();
        }
        catch(err: any){
            setLoggingOut(false);
        }

        history.replace("/login");
        window.location.href = `${window.location.origin}/login`;
    };

    const openHelpMenu = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        document.removeEventListener("click", onAnywhereElseClicked);
        document.addEventListener("click", onAnywhereElseClicked);

        setCodesMenuOpen(false);
        setHelpMenuOpen(true);
    };

    const openCodesMenu = (ev: React.MouseEvent<HTMLDivElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        document.removeEventListener("click", onAnywhereElseClicked);
        document.addEventListener("click", onAnywhereElseClicked);

        setHelpMenuOpen(false);
        setCodesMenuOpen(true);
    };

    //const showHelp = (userState.data.HelpUrl || userState.data.PrivacyUrl || userState.data.SupportUrl);

    const onWelcomePage = async (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        ev.preventDefault();
        ev.stopPropagation();
        document.removeEventListener("click", onAnywhereElseClicked);

        try{
            await setUserAdminWelcome(true);
        }
        catch(err: any) {
            console.error(err);
        }

        setUserState({...userState, data: {...userState.data, AdminWelcome: true}});
        goTo("/admin");
    };

    const onCloseMenu = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.stopPropagation();
        document.removeEventListener("click", onAnywhereElseClicked);
        
        setHelpMenuOpen(false);
        setCodesMenuOpen(false);
    };

    const onCodeClicked = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>, type: string) => {
        ev.preventDefault();
        ev.stopPropagation();
        document.removeEventListener("click", onAnywhereElseClicked);

        setHelpMenuOpen(false);
        setCodesMenuOpen(false);

        if(onCodeClick)
            onCodeClick(type);
    };

    return (
        <div className="flex-row">
            {hasLogo && <div>
                <img className="margin-15" width="300" height="144" src="assets/TAAPHere.png" alt="TAAP Here Visitor Book" title=" Visitor Book" />
            </div>}
            <div className="flex-fill"></div>
            <div>
                <div className={styles.menu}>
                    <div className={styles.fill}></div>
                    {isAdmin && <button className="btn" onClick={_ => goTo("/")}>VISITOR BOOK</button>}
                    {userState.data.Role === "Admin" && !isAdmin && <button className="btn" onClick={_ => goTo(userState.data.AdminWelcome ? "/admin" : "/admin/visitors")}>ADMIN</button>}
                    {onCodeClick && (
                        <div className={`btn margin-right-15 ${styles.dropdown}`} onClick={openCodesMenu}>
                            QR CODES
                            {codesMenuOpen && (
                                <>
                                    <div className={styles.dropdownMenuContainer}>
                                        <div className={styles.dropdownMenu}>
                                            <span className={styles.dropdownMenuItem} onClick={ev => onCodeClicked(ev, "SignIn")}>SIGN IN</span>
                                            <span className={styles.dropdownMenuItem} onClick={ev => onCodeClicked(ev, "SignOut")}>SIGN OUT</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    {isAdmin && <button className="btn" onClick={_ => goTo("/admin/visitors")}>SEARCH</button>}
                    {isAdmin && <button className="btn" onClick={_ => goTo("/admin/locations")}>LOCATIONS</button>}
                    {isAdmin && <button className="btn" onClick={_ => goTo("/admin/users")}>USERS</button>}
                    {isAdmin && <button className="btn" onClick={_ => goTo("/admin/addin")}>ADD-IN</button>}
                    <div className={`btn margin-right-15 ${styles.dropdown}`} onClick={openHelpMenu}>
                        HELP
                        {helpMenuOpen && (
                            <>
                                <div className={styles.dropdownMenuContainer}>
                                    <div className={styles.dropdownMenu}>
                                        {userState.data.Role === "Admin" && <span className={styles.dropdownMenuItem} onClick={onWelcomePage}>WELCOME PAGE</span>}
                                        {userState.data.HelpUrl && <a className={styles.dropdownMenuItem} onClick={onCloseMenu} href={userState.data.HelpUrl} target="_blank" rel="noopener noreferrer">HELP</a>}
                                        {userState.data.PrivacyUrl && <a className={styles.dropdownMenuItem} onClick={onCloseMenu} href={userState.data.PrivacyUrl}  target="_blank" rel="noopener noreferrer">PRIVACY</a>}
                                        {userState.data.SupportUrl && <a className={styles.dropdownMenuItem} onClick={onCloseMenu} href={userState.data.SupportUrl}  target="_blank" rel="noopener noreferrer">SUPPORT</a>}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <button className={`btn${loggingOut ? " loading": ""}`} onClick={onLogout}>LOG OUT</button>
                </div>

                <div className="text-right">
                    {userState.data.DisplayName && <div className={styles.title}>{userState.data.DisplayName}</div>}
                    {userState.data.ClientName && <div className={styles.title}>{userState.data.ClientName}</div>}
                    {userState.data.ClientDemo && <div className={styles.title}>DEMO</div>}
                </div>
            </div>
        </div>
    );   
};