import React, { useEffect, useRef, useState } from 'react';
import { Menu } from '../../components/Menu/Menu';
import { Loading } from '../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import styles from './AdminUsers.module.css';
import { getUsers, User, NewUser, addUser } from '../../api/users';
import { Modal } from '../../components/Modal/Modal';
import { getLocations, Location } from '../../api/locations';
import { UserForm } from '../../components/UserForm/UserForm';
import { Tabs } from '../../components/Tabs/Tabs';
import { addClientRegisteredUser, ClientRegisteredUser, getClientRegisteredUsers, importClientRegisteredUsersCSV, saveClientRegisteredUser } from '../../api/client-registered-users';
import { CheckBox } from '../../components/CheckBox/CheckBox';
import { downloadBlob } from '../../utils/download.utils';
import { useMessageBoxState } from '../../contexts/message-box.context';

const defaultUserToAdd = { Id: 0, DisplayName: "", Email: "", Password: "", DefaultLocationId: 0, Role: "User" };
const defaultClientRegisteredUser: ClientRegisteredUser = { Id: 0, ClientId: 0, Name: "", Email: "", Category: "", Active: true, DeviceRegistered: false };

export const AdminUsers: React.FC = () => {

    const history = useHistory();
    
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [userToAdd, setUserToAdd] = useState<NewUser>(defaultUserToAdd);
    const [addingUser, setAddingUser] = useState<boolean>(false);

    const [clientRegisteredUsers, setClientRegisteredUsers] = useState<ClientRegisteredUser[]>([]);
    const [currentCRU, setCurrentCRU] = useState<ClientRegisteredUser>(defaultClientRegisteredUser);
    const [searchingCRUs, setSearchingCRUs] = useState<boolean>(false);
    const [addEditingCRU, setAddEditingCRU] = useState<boolean>(false);
    const [addEditClientRegisteredUserModalOpen, setAddEditClientRegisteredUserModalOpen] = useState<boolean>(false);
    const [CRUsearch, setCRUSearch] = useState<string>("");

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [locations, setLocations] = useState<Location[]>();
    const [addErrorMessage, setAddErrorMessage] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
    const importInputRef = useRef<HTMLInputElement>();
    const { setMessageBoxState } = useMessageBoxState();

    const goTo = (route: string) => {
        history.push(route);
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await getUsers();
                setUsers(res);
                setFilteredUsers(res);

                const crRes = await getClientRegisteredUsers({ ExcludeNullDeviceIds: false, ExcludeInactive: false, Search: null });
                setClientRegisteredUsers(crRes);

                const locs = await getLocations();
                setLocations(locs);
            }
            catch(err: any) {}
        })();
    }, []);

    let renderedUsers = null;
    if(users){
        renderedUsers = filteredUsers.map(u => (
            <tr key={u.Id.toString()}>
                <td>{u.DisplayName}</td>
                <td>{u.Email}</td>
                <td>{u.DefaultLocationName}</td>
                {u.Active ? <td>&#10004;</td> : <td>&#10006;</td>}
                <td>
                    <button className={`btn ${styles.actionButton}`} onClick={_ => goTo(`/admin/users/${u.Id}`)}>VIEW / EDIT</button>
                </td>
            </tr>
        ));
    }

    let renderedClientRegisteredUsers = null;
    if(clientRegisteredUsers){
        renderedClientRegisteredUsers = clientRegisteredUsers.map(u => (
            <tr key={u.Id.toString()}>
                <td>{u.Name}</td>
                <td>{u.Email}</td>
                <td>{u.Category}</td>
                {u.DeviceRegistered ? <td>&#10004;</td> : <td>&#10006;</td>}
                {u.Active ? <td>&#10004;</td> : <td>&#10006;</td>}
                <td>
                    <button className={`btn ${styles.actionButton}`} onClick={_ => onEditCRU(u)}>VIEW / EDIT</button>
                </td>
            </tr>
        ));
    }

    const onAddUser = () => {
        setUserToAdd(defaultUserToAdd);

        setAddErrorMessage("");
        setModalOpen(true);
    };

    const onAddCRU = () => {
        setCurrentCRU(defaultClientRegisteredUser);

        setAddErrorMessage("");
        setAddEditClientRegisteredUserModalOpen(true);
    };
    
    const onEditCRU = (u: ClientRegisteredUser) => {
        setCurrentCRU(u);

        setAddErrorMessage("");
        setAddEditClientRegisteredUserModalOpen(true);
    };

    const onSaveAddLocation = async () => {
        setAddingUser(true);

        try{
            await addUser(userToAdd);
            setAddingUser(false);
            setModalOpen(false);

            const res = await getUsers();
            setUsers(res);
            setSearch("");
            setFilteredUsers(res);
        }
        catch(err: any) {
            setAddingUser(false);
            setAddErrorMessage(err.message);
        }
    };

    const onSaveCRU = async () => {
        setAddEditingCRU(true);

        try{
            if(currentCRU.Id !== 0) {
                await saveClientRegisteredUser(currentCRU);
            }
            else{
                await addClientRegisteredUser(currentCRU);
            }
            setAddEditingCRU(false);
            setAddEditClientRegisteredUserModalOpen(false);

            const res = await getClientRegisteredUsers({ ExcludeNullDeviceIds: false, ExcludeInactive: false, Search: CRUsearch });
            setClientRegisteredUsers(res);
        }
        catch(err: any) {
            setAddEditingCRU(false);
            setAddErrorMessage(err.message);
        }
    };

    const onSearch = () => {
        if(!search){
            setFilteredUsers(users);
            return;
        }

        const lowerSearch = search.toLowerCase();
        setFilteredUsers(users?.filter(u => (
            (u.DisplayName && u.DisplayName.toLowerCase().includes(lowerSearch)) ||
            (u.Email && u.Email.toLowerCase().includes(lowerSearch)) ||
            (u.DefaultLocationName && u.DefaultLocationName.toLowerCase().includes(lowerSearch))
            )) || users);
    };

    const onClientRegisteredUserSearch = async () => {
        setSearchingCRUs(true);
        const res = await getClientRegisteredUsers({ ExcludeNullDeviceIds: false, ExcludeInactive: false, Search: CRUsearch });
        setClientRegisteredUsers(res);
        setSearchingCRUs(false);

        // const lowerSearch = CRUsearch.toLowerCase();
        // setFilteredClientRegisteredUsers(clientRegisteredUsers?.filter(u => (
        //     (u.Name && u.Name.toLowerCase().includes(lowerSearch)) ||
        //     (u.Email && u.Email.toLowerCase().includes(lowerSearch)) ||
        //     (u.Category && u.Category.toLowerCase().includes(lowerSearch))
        //     )) || users);
    };

    const onDownloadCsvTemplate = () => {
        const text = "Name,Email,Category";
        const blob = new Blob([text], { type: "text/csv" });
        downloadBlob("ClientRegisteredUsersUploadTemplate.csv", blob);
    };

    const onUploadClientRegisteredUsers = () => {
        if(importInputRef.current) {
            importInputRef.current.click();
        }
    };

    const onImportCSVSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setImportModalOpen(false);

        if(!importInputRef.current || !ev.target.files || ev.target.files.length === 0)
            return;

        const file = ev.target.files[0];
        if(!file)
            return;

        const reader = new FileReader();
        reader.onload = () => {
            if(importInputRef.current)
                (importInputRef.current as any).value = null;

            let csv = reader.result as string;
            // Trim whitespace and most importantly and line breaks off the end to ensure validation doesnt fail on a blank newline at the end (thanks excel)
            csv = csv.trimEnd();

            if(tryParseImportCSVString(csv)) {
                setMessageBoxState({
                    open: true,
                    title: "Upload Client Registered Users CSV",
                    message: `Are you sure you want to upload this CSV file? the users in this file will be merged with any that currently exist.`,
                    okayButtonText: "YES, UPLOAD",
                    cancelButtonText: "NO, CANCEL",
                    options: {
                        hasCancel: true,
                        redOkayButton: false
                    },
                    callback: async (confirmed) => {
                        if(!confirmed){
                            return;
                        }
        
                        try {
                            const result = await importClientRegisteredUsersCSV({ CSV: csv });
                            setMessageBoxState({
                                open: true,
                                title: "Users Uploaded",
                                message: `${result.NumAdded} client registered users have been added successfully.`,
                                options: {
                                    hasCancel: false,
                                    redOkayButton: false
                                }
                            });

                            await onClientRegisteredUserSearch();
                        }
                        catch(err: any) {
                            showMessageBoxError(err.message);
                        }
                    }
                });
            }
        };
        reader.readAsText(file);
    };

    const tryParseImportCSVString = (csv: string): boolean => {
        const errorMessage = "Upload CSV validation error. Please ensure you are uploading a CSV file.  The CSV has only 3 columns with titles \"Name\", \"Email\" and \"Category\". There must be at least 1 entry and all entries must have a value against all columns. Values cannot have \",\" characters or line breaks.";
        
        const lineSplit = csv.split("\n");
        if(lineSplit.length < 2) {
            showMessageBoxError(errorMessage);
            return false;    
        }

        for(const line of lineSplit) {
            const colSplit = line.split(",");
            if(colSplit.length !== 3 || !colSplit[0].trim() || !colSplit[1].trim() || !colSplit[2].trim()){
                showMessageBoxError(errorMessage);
                return false;
            }
        }

        return true;
    };

    const showMessageBoxError = (message: string) => {
        setMessageBoxState({
            open: true,
            title: "Error",
            message: message,
            options: {
                hasCancel: false,
                redOkayButton: false
            }
        });
    };

    return (
        <>
            <Menu isAdmin={true} />
            <div className="container">
                {!users && <Loading />}
                {users && clientRegisteredUsers && (
                    <>
                        <Tabs tabs={[
                            {
                                label: "Users",
                                component: (
                                    <>
                                        <div className="form-control" style={{ maxWidth: "300px" }}>
                                            <div className="label">Search</div>
                                            <input type="text" value={search} onKeyUp={onSearch} onChange={ev => setSearch(ev.target.value)} />
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-large-title">Users</div>
                                            <div className="flex-fill"></div>
                                            <button className="btn" onClick={onAddUser}>ADD USER</button>
                                        </div>
                                        <table className="table table-shaded-header table-font-large">
                                            <thead>
                                            <tr>
                                                <th>Display Name</th>
                                                <th>Email</th>
                                                <th>Default Location</th>
                                                <th>Active</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {renderedUsers}
                                            </tbody>
                                        </table>
                                    </>
                                )
                            },
                            {
                                label: "Client Registered Users",
                                component: (
                                    <>
                                        <p className="no-margin-bottom">Search and add users who can then be mapped to multiple Visitor Types across different locations</p>
                                        <div className="flex-row margin-bottom-15">
                                            <div className="form-control no-margin-bottom" style={{ maxWidth: "300px" }}>
                                                <div className="label">Search</div>
                                                <input type="text" value={CRUsearch} onChange={ev => setCRUSearch(ev.target.value)} />
                                            </div>
                                            <button className={`btn margin-left-15 flex-align-self-end${searchingCRUs ? " loading" : ""}`} onClick={onClientRegisteredUserSearch}>Search</button>
                                        </div>
                                        <div className="flex-row">
                                            <div className="flex-large-title">Client Registered Users</div>
                                            <div className="flex-fill"></div>
                                            <button className="btn margin-left-15" onClick={_ => setImportModalOpen(true)}>UPLOAD</button>
                                            <button className="btn margin-left-15" onClick={onAddCRU}>ADD USER</button>
                                        </div>
                                        <table className="table table-shaded-header table-font-large">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Category</th>
                                                <th>App Registered</th>
                                                <th>Active</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {renderedClientRegisteredUsers}
                                            </tbody>
                                        </table>
                                    </>
                                )
                            }
                        ]} />
                        
                    </>
                )}
            </div>

            {modalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Add User</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setModalOpen(false)}>&#10006;</button>
                    </div>
                    
                    <UserForm user={userToAdd} locations={locations || []} onChange={u => setUserToAdd(u as NewUser)} password={true} />

                    <div>
                        <button className={`btn${addingUser ? " loading" : ""}`} onClick={onSaveAddLocation}>SAVE</button>
                    </div>

                    <div className={styles.error}>{addErrorMessage}</div>
                </Modal>
            )}

            {importModalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">Upload Client Registered Users</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setImportModalOpen(false)}>&#10006;</button>
                    </div>
    
                    <p>Download the CSV template to populate for upload.</p>
                    <div>
                        <button className="btn margin-bottom-15" onClick={onDownloadCsvTemplate}>DOWNLOAD CSV TEMPLATE</button>
                    </div>

                    <div>
                        <button className="btn" onClick={onUploadClientRegisteredUsers}>UPLOAD</button>
                    </div>
                </Modal>
            )}

            <input style={{ display: "none" }} type="file" accept="text/csv,.csv" onChange={onImportCSVSelected} ref={r => { if(r) importInputRef.current = r; }} />

            {addEditClientRegisteredUserModalOpen && (
                <Modal>
                    <div className="flex-row">
                        <div className="flex-title">{currentCRU.Id === 0 ? "Add Client Registered User" : "Edit Client Registered User"}</div>
                        <div className="flex-fill"></div>
                        <button className="btn" onClick={_ => setAddEditClientRegisteredUserModalOpen(false)}>&#10006;</button>
                    </div>
                    
                    <div className="form-control">
                        <div className="label">Name</div>
                        <input type="text" value={currentCRU.Name} onChange={ev => setCurrentCRU({...currentCRU, Name: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Email</div>
                        <input type="text" value={currentCRU.Email} onChange={ev => setCurrentCRU({...currentCRU, Email: ev.target.value})} />
                    </div>

                    <div className="form-control">
                        <div className="label">Category</div>
                        <input type="text" value={currentCRU.Category} onChange={ev => setCurrentCRU({...currentCRU, Category: ev.target.value})} />
                    </div>

                    <CheckBox label="Active" checked={currentCRU.Active} onChecked={c => setCurrentCRU({...currentCRU, Active: c})} />

                    <div>
                        <button className={`btn${addEditingCRU ? " loading" : ""}`} onClick={onSaveCRU}>SAVE</button>
                    </div>

                    <div className={styles.error}>{addErrorMessage}</div>
                </Modal>
            )}
        </>
    );
};