import { req, apiUrl } from "./helpers";
import { USER_TOKEN_NAME } from "./auth";

export type ClientRegisteredUser = {
    Id: number;
    ClientId: number;
    Name: string;
    Email: string;
    Category: string;
    Active: boolean;
    DeviceRegistered: boolean;
}

export type ClientRegisteredUserWithDeviceId = ClientRegisteredUser & {
    DeviceId: string
}

export type GetClientRegisteredUsersOptions = {
    ExcludeNullDeviceIds: boolean, 
    ExcludeInactive: boolean,
    Search: null | string
}

export type getClientRegisteredUsersWithDeviceIdsOptions = {
    Search: null | string
}

export const getClientRegisteredUsers = (options: GetClientRegisteredUsersOptions): Promise<ClientRegisteredUser[]> => {
    return req("POST", apiUrl("/api/GetClientRegisteredUsers"), options, USER_TOKEN_NAME);
};

export const getClientRegisteredUsersWithDeviceIds = (options: getClientRegisteredUsersWithDeviceIdsOptions): Promise<ClientRegisteredUserWithDeviceId[]> => {
    return req("POST", apiUrl("/api/GetClientRegisteredUsersWithDeviceIds"), options, USER_TOKEN_NAME);
};

export const saveClientRegisteredUser = (user: ClientRegisteredUser): Promise<void> => {
    return req("POST", apiUrl("/api/SaveClientRegisteredUser"), user, USER_TOKEN_NAME);
};
export const addClientRegisteredUser = (newUser: ClientRegisteredUser) => {
    return req("POST", apiUrl("/api/AddClientRegisteredUser"), newUser, USER_TOKEN_NAME);
};

export type importClientRegisteredUsersCSVResponse = {
    NumAdded: number
}

export const importClientRegisteredUsersCSV = (request: { CSV: string }): Promise<importClientRegisteredUsersCSVResponse> => {
    return req("POST", apiUrl("/api/ImportClientRegisteredUsersCSV"), request, USER_TOKEN_NAME);
};