import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from '../../components/Menu/Menu';
import styles from './Admin.module.css';
import { useUserState } from '../../contexts/user.context';
import { CheckBox } from '../../components/CheckBox/CheckBox';
import { setUserAdminWelcome } from '../../api/users';

export const Admin: React.FC = () => {

    const history = useHistory();
    const { userState, setUserState } = useUserState();
    const [dontShowAgain, setDontShowAgain] = useState<boolean>(false);

    // const goTo = (route: string) => {
    //     history.push(route);
    // };

    const onDontShowAgain = async (checked: boolean) => {
        setDontShowAgain(checked);
        const adminWelcome = !checked;

        try{
            await setUserAdminWelcome(adminWelcome);
            setUserState({...userState, data: {...userState.data, AdminWelcome: adminWelcome}});
            history.replace("/admin/visitors");
        }
        catch(err: any) {
            console.error(err);
        }
    };

    return (
        <>
            <Menu isAdmin={true} />

            {userState.data.AdminWelcome && (
                <div className={styles.welcomeIframeContainer}>
                    <iframe className={styles.welcomeIframe} src="https://ontaap.com/visitorbook/welcomepage" frameBorder={0} title="Welcome"></iframe>
                    <div className="text-center">
                        <CheckBox style={{ display: "inline-block" }} label="Don't show again" sideLabel={true} checked={dontShowAgain} onChecked={onDontShowAgain} />
                    </div>
                </div>
            )}
        </>
    );
};

/*
<div className="container">
    <div className="flex-row">
        <div className="flex-fill-50 padding-15">
            <div>
                <button className="btn full-width" onClick={_ => goTo("/admin/visitors")}>SEARCH</button>
            </div>
            <p>Search visitors from the past 30 days and export visitor data</p>
        </div>
        <div className="flex-fill-50 padding-15">
            <div>
                <button className="btn full-width" onClick={_ => goTo("/admin/locations")}>LOCATIONS</button>
            </div>
            <p>Click on 'LOCATIONS' to add/edit a location, the mandatory fields required to be completed by your visitors and download and print the sign in and sign out QR codes.</p>
        </div>
        <div className="flex-fill-50 padding-15">
            <div>
                <button className="btn full-width" onClick={_ => goTo("/admin/users")}>USERS</button>
            </div>
            <p>Click on 'USERS' to manage users, default locations and roles.</p>
        </div>
        <div className="flex-fill-50 padding-15">
            <div>
                <button className="btn full-width" onClick={_ => goTo("/admin/addin")}>ADD-IN</button>
            </div>
            <p>Click on 'ADD-IN' to download the add-in and see instructions for installation.</p>
        </div>
    </div>
</div>
*/